import { Button } from "@material-ui/core";
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { EventsContext } from "../../context/EventsContext";
import { Whoops } from "../Errors";
import { EventsDayContainer } from "./EventsDayContainer";

export const EventsList = () => {
  const { events, loadNextDay, loading, error, moreDataAvailable, noEventsAvailable } = useContext(EventsContext);
  const { t } = useTranslation();

  useEffect(() => {
    const onScroll = (e) => {
      if (moreDataAvailable && !loading && window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        loadNextDay();
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [moreDataAvailable, loading, loadNextDay]);

  if (error) return <Whoops />;

  return (
    <div>
      <h2>{t("Events.Headline")} </h2>
      <div>
        {events.map((eventsOfDay, index) => (
          <EventsDayContainer key={index} events={eventsOfDay} />
        ))}
      </div>
      {moreDataAvailable && events.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Button
            disabled={!moreDataAvailable || loading}
            onClick={loadNextDay}
            style={{ marginTop: 150, marginBottom: 50 }}
          >
            {loading ? t("Events.Loading") : t("Events.ShowNextDay")}
          </Button>
        </div>
      )}
      {noEventsAvailable && <p>{t("Events.NoEventsAvailable")}</p>}
    </div>
  );
};
