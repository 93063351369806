import React, { useRef, useState, useEffect } from "react";

import {
  IconButton,
  Dialog,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  DialogContent
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { colors } from "../../theme";
const useStyles = makeStyles((theme) => ({
  iconButton: {
    position: "absolute",
    top: 10,
    right: 10,
    width: 30,
    height: 30,
    backgroundColor: colors.actyLightBlue,
    color: "white",
    "&:hover": {
      backgroundColor: colors.actyDarkBlue
    }
  },
  tocDialog: {
    [theme.breakpoints.down("xs")]: {
      height: 500
    },
    [theme.breakpoints.down("xl")]: {
      height: 800
    }
  }
}));
export default function ScrollDialog({ open, handleClose, content = null }) {
  const [scroll, setScroll] = useState("paper");
  const [cleanHtml, setCleanHtml] = useState(null);
  const styles = useStyles();

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  useEffect(() => {
    if (content.tcHtml !== null) {
      const html = content.tcHtml.html
        .replace(/[\n\r]+/g, "")
        .replace(/\<\!DOCTYPE html\>/, "");

      setCleanHtml(html);
    }
  }, [content]);
  if (content?.tcHtml !== null && open && cleanHtml !== null) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="scroll-dialog-title">
          <IconButton
            size="medium"
            className={styles.iconButton}
            onClick={handleClose}
            disableFocusRipple={true}
            disableRipple={true}
          >
            <CloseIcon
              style={{
                marginTop: 11
              }}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent className={styles.tocDialog}>
          <iframe
            title="actyvyst AGBs"
            style={{ width: "100%", height: "100%", border: "none" }}
            srcdoc={`${cleanHtml}`}
          ></iframe>
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
}
