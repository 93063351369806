import React, { useContext } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import OfferCard from "../OfferCard/";
import { AdCard } from "../AdCard";
import MobileOfferCard from "../OfferCard/MobileOfferCard";
import FilterBox from "../FilterBox";
import ActivityListHeader from "./ActivityListHeader";
import Header from "../../containers/Header";
import Config from "../../config";
import slugify from "slugify";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isWhiteLabel, getProviderId } from "../../util/get-destination";

const { imagePath } = Config;

export default function ActivityList(props) {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const { activities, categories } = props;
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const OfferComponent = isMobile ? MobileOfferCard : OfferCard;
  const leftColWidth = isMedium || isWhiteLabel ? 0 : 3;
  return (
    <Grid container>
      {leftColWidth > 0 ? (
        <Grid item container xs={leftColWidth}>
          <div style={{ backgroundColor: "undefined", width: "100%" }}>
            <FilterBox categories={categories} />
          </div>
        </Grid>
      ) : undefined}
      <Grid item container xs={12 - leftColWidth}>
        <ActivityListHeader showFilter={isMedium} categories={categories} />
        <Grid item xs={12} style={{ paddingTop: 18 }}>
          <AdCard />
        </Grid>
        {activities.map((activity, index) => {
          const slug = encodeURIComponent(
            slugify(activity.name, {
              lower: true,
              locale: i18n.language,
              strict: true,
            })
          );
          return (
            <Grid key={activity.id} item xs={12} style={{ paddingTop: 18 }}>
              <OfferComponent
                id={activity.id}
                title={activity.name}
                teaser={activity.teaser}
                description={activity.description}
                image={{
                  url: activity.images.length > 0 ? `${imagePath}/${activity.images[0].url}` : "",
                  altText: activity.images.length > 0 ? activity.images[0].description : "",
                }}
                isBookable={activity.bookable}
                onClick={() => {
                  window.dataLayer.push({
                    event: "selectOffer",
                    offerTitle: activity.name,
                    offerId: activity.id,
                    offerCardPosition: index,
                  });
                  history.push(`/${slug}/?a_id=${activity.id}&pid=${getProviderId(window.location.href)}`);
                }}
                link={`/${slug}/?a_id=${activity.id}&pid=${getProviderId(window.location.href)}`}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
