import { useData } from "./useData";

function useEventsUpcoming({ fromDay, days }) {
  const query = new URLSearchParams();
  if (fromDay * 1 > 0) {
    query.append("from", fromDay);
  }
  if (days * 1 >= 0) {
    query.append("days", days);
  }
  const baseUrl = "/activities/events/today";
  const url = Array.from(query).length > 0 ? `${baseUrl}?${query}` : baseUrl;
  const { data, loading, error, retry, isInitialized } = useData(url);
  return {
    data: data,
    loading,
    error,
    retry,
    isInitialized,
  };
}

function useEventsNext() {
  const { data, loading, error, retry, isInitialized } = useData("/activities/events/next");
  return {
    data: data,
    loading,
    error,
    retry,
    isInitialized,
  };
}

export { useEventsUpcoming, useEventsNext };
