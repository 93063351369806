import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import TagManager from 'react-gtm-module'

const getTag = (domainName) => {
  const defaultTag = 'GTM-WQM78GB'
  if (!domainName) return defaultTag;
  const parts = domainName.split('.');
  const topLevelDomain = parts.pop() || 'de';
  switch (topLevelDomain) {
    case 'com':
      return 'GTM-N537K75';
    case 'de':
    default:
      return defaultTag;
  }
}

const tagManagerArgs = {
  gtmId: getTag(window.location.hostname)
}
TagManager.initialize(tagManagerArgs);

const TrackingWrapper = (props) => {
  // const location = useLocation();
  // useEffect(() => {
  //   // console.log('----------LOCATION-CHANGE----------');
  //   // console.log({ location: location.pathname, path: window.location.hostname + window.location.pathname + window.location.search });
  //   // console.log('--------END-LOCATION-CHANGE--------');
  //   window.dataLayer.push({
  //     event: 'pageview'
  //   });
  // }, [location])
  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}

export default TrackingWrapper;

