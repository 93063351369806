import React, { PropTypes, Component } from "react";
import GoogleMapReact from "google-map-react";
import { colors } from "../../../src/theme";

const MapView = ({ coords }) => {
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: 400, width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyC1JGJ9alZAzU2zOwwIx-GFYXobwm6anmY" }}
        defaultZoom={12}
        center={{ lat: coords.lat, lng: coords.lng }}
      >
        <Marker lat={coords.lat} lng={coords.lng} />
      </GoogleMapReact>
    </div>
  );
};

const Marker = () => {
  const markerStyle = {
    border: "1px solid white",
    borderRadius: "50%",
    height: 12,
    width: 12,
    backgroundColor: colors.actyLightBlue,
    cursor: "pointer",
    zIndex: 10
  };

  return <div style={markerStyle} />;
};

export default MapView;
