import React from "react";
import {
  makeStyles,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent
} from "@material-ui/core";
import { colors } from "../../theme";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment/min/moment-with-locales";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  errorDialogBackground: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.actyLightBlue,
    marginTop: 15,
    width: "90%",
    height: "auto"
  },
  errorTitleBar: {
    backgroundColor: "white",

    padding: 10,
    color: colors.actyRed
  },
  ticketBody: {
    backgroundColor: "white",

    padding: 10,
    color: colors.actyDarkBlue
  },
  errorTitle: {
    color: "white",
    marginTop: 0
  },
  iconHolder: {
    position: "absolute",
    top: 20,
    right: 50,
    width: 30,
    backgroundColor: colors.actyBackground
  },
  icons: {
    color: colors.actyError
  },
  iconButton: {
    position: "absolute",
    top: 10,
    right: 0,
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
}));
const ErrorDialog = ({
  errorTitle,
  showError = false,
  errorMessage,
  closeHandler
}) => {
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language);
  console.log("ErrorTitle: ", errorTitle);

  return (
    <Dialog
      open={showError}
      onClose={closeHandler}
      aria-labelledby="Error-dialog"
      aria-describedby="Error-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="Error-dialog-title">
        <IconButton
          size="medium"
          className={styles.iconButton}
          onClick={closeHandler}
          disableFocusRipple={true}
          disableRipple={true}
        >
          <CloseIcon className={styles.icons} />
        </IconButton>
        <Typography variant="h6" align="left" color="error">
          {!errorTitle ? t("APIErrors.booking.GENERIC") : errorTitle}
        </Typography>
      </DialogTitle>
      <DialogContent className={styles.tocDialog}>
        <Typography variant="body2" align="left" color="primary">
          {errorMessage}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorDialog;
