import axios from "axios";
import deserializer from "./deserializer";
import config from "../config";
import ActyvystAPIError from "./ApiError";
import { useData } from "./useData";
import { isWhiteLabel } from "../util/get-destination";

const { apiPath } = config;

const JSONAPISerializer = require("jsonapi-serializer").Serializer;

const BookingSerializer = new JSONAPISerializer("booking", {
  keyForAttribute: "camelCase",
  attributes: ["slot", "booker", "priceBreakdown", "client", "platform"],
  booker: {
    attributes: ["name", "email", "phone"],
  },
  priceBreakdown: {
    attributes: ["currency", "categories"],
    categories: {
      attributes: ["id", "numTickets", "allowedParticipants", "name", "description", "price"],
    },
  },
  client: {
    attributes: ["rebate", "total"],
  },
  platform: {
    attributes: ["channel", "whiteLabel"],
  },
});

const createBooking = async (slot, priceCategories) => {
  const BookingRequest = {
    slot: slot.id,
    priceBreakdown: {
      currency: "eur",
      categories: priceCategories,
    },
    client: {
      rebate: 0,
      total: priceCategories.reduce((acc, cur) => {
        return acc + cur.numTickets * cur.price;
      }, 0),
    },
    platform: {
      channel: "web",
      whiteLabel: isWhiteLabel,
    },
  };
  const serializedRequest = BookingSerializer.serialize(BookingRequest);
  console.log(serializedRequest);
  try {
    const result = await axios.post(`${apiPath}/activities/bookings`, serializedRequest);
    const booking = await deserializer.deserialize(result.data);
    return booking;
  } catch (err) {
    const error = ActyvystAPIError.createFromAxiosError(err);
    throw error;
  }
};
function useBooking(bookingid) {
  const { data, error, loading, retry } = useData(`/activities/bookings/${bookingid}`);
  return {
    booking: data,
    bookingError: error,
    bookingLoading: loading,
    retry: retry,
  };
}

const confirmPayment = async (bookingid) => {
  try {
    const uri = `${apiPath}/activities/bookings/${bookingid}/book`;
    const response = await axios.post(uri, {});
    const result = await deserializer.deserialize(response.data);
    return result;
  } catch (err) {
    const error = ActyvystAPIError.createFromAxiosError(err);
    console.log("ActyvystError: ", error);
    throw error;
  }
};
function useGtc() {
  const { data, error, loading, retry } = useData(`/actyvystgtc`);
  return {
    tcHtml: data,
    tcContentError: error,
    tcContentLoading: loading,
    retry: retry,
  };
}

export { createBooking, useBooking, confirmPayment, useGtc };
