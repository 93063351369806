import React from "react";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { colors } from "../../theme";
import { Grid, makeStyles, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  calIcon: {
    color: colors.actyLightBlue,

    minWidth: "40%",
    minHeight: "40%"
  },
  dayLabel: {
    position: "absolute",
    paddingTop: 10,
    zIndex: 4,

    fontSize: 14
  },
  container: {
    minWidth: "40%",
    minHeight: "40%",
    position: "absolute",
    backgroundColor: "red"
  }
}));
const CalendarIcon = ({ day }) => {
  const style = useStyles();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h2" color="secondary" className={style.dayLabel}>
        {day}
      </Typography>
      <CalendarTodayIcon fontSize="large" className={style.calIcon} />
    </Grid>
  );
};
export default CalendarIcon;
