import { Card, CardMedia, CardContent, CardActionArea, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import { colors } from "../../theme";

export const AdCardMobile = (props) => {
  const { image, title, teaser, link, isActive: activeCard } = props;
  const history = useHistory();

  return (
    <LazyLoad once>
      <Card
        onClick={() => {
          history.push(link);
        }}
        style={{
          borderColor: activeCard ? colors.actyRed : colors.actyDarkBlue,
          borderWidth: activeCard ? 1 : 0,
          borderStyle: activeCard ? "solid" : "none",
        }}
      >
        <CardActionArea>
          {activeCard ? undefined : (
            <div style={{ padding: "15px 15px 0px 15px " }}>
              <CardMedia component="img" alt={image.altText} image={image.url} title={image.altText}></CardMedia>
            </div>
          )}

          <CardContent>
            <Typography component="h4" variant="h4" style={{ marginBottom: 0 }}>
              {title}
            </Typography>
            <Typography variant="subtitle1" color="secondary" style={{ marginBottom: 3 }}>
              {teaser}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </LazyLoad>
  );
};

AdCardMobile.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    altText: PropTypes.string,
  }),
  title: PropTypes.string,
  teaser: PropTypes.string,
  link: PropTypes.string,
};
