import { useData } from "./useData";

function useAppConfig() {
  const { data, loading, error, retry } = useData("/appconfig");
  return {
    appConfigData: data || [],
    appConfigLoading: loading,
    appConfigError: error,
    appConfigRetry: retry,
  };
}

export { useAppConfig };
