import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MainRoutes from "./routes/MainRoutes";
import { ThemeProvider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { destination } from "./util/get-destination";
import constants from "./constants";
import theme from "./theme";
import CookieConsent from "react-cookie-consent";
import TrackingWrapper from "./containers/TrackingWrapper";
import { useTranslation } from "react-i18next";
import qs from "qs";
import { cookieConsent } from "./util/get-cookie-consent";
import { AppContextProvider } from "./context/AppContext";

const { destinations } = constants;
const destinationInfo = destinations[destination];

function App() {
  const languages = destinationInfo.languages;
  const { i18n, t } = useTranslation();
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  useEffect(() => {
    if (query.language && languages.find((language) => query.language.toLocaleLowerCase() === language)) {
      i18n.changeLanguage(query.language);
      localStorage.setItem("language", query.language);
    } else {
      const language = localStorage.getItem("language");
      if (language && languages.find((l) => language === l)) {
        i18n.changeLanguage(language);
      }
    }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name="apple-itunes-app" content={`app-id=${destinationInfo?.appstoreId}`} />
        <meta name="description" content={`actyvyst ${destinationInfo?.name} - ${t("AppInfos.MetaClaim")}`} />
        <title>{`actyvyst ${destinationInfo?.name} - ${t("AppInfos.MetaClaim")}`}</title>
        <meta property="og:title" content={`actyvyst ${destinationInfo?.name} - ${t("AppInfos.MetaClaim")}`} />
        <meta property="og:description" content={t("AppInfos.MetaClaim")} />
      </Helmet>
      <Router>
        <TrackingWrapper>
          {cookieConsent ? undefined : (
            <CookieConsent
              style={{ paddingBottom: 24 }}
              // visible={cookieConsent === true ? "hidden" : "show"}
              location="bottom"
              buttonText={t("AppInfos.CookieConsentAgree")}
              buttonStyle={{
                borderRadius: 8,
                backgroundColor: theme.palette.secondary.main,
                color: "white",
                padding: 12,
                justifySelf: "center",
              }}
              onAccept={() => {
                localStorage.setItem("cookie-consent", "true");
              }}
              onDecline={() => {
                localStorage.setItem("cookie-consent", "false");
              }}
            >
              {t("AppInfos.CookieConsentText")}
            </CookieConsent>
          )}
          <AppContextProvider>
            <MainRoutes />
          </AppContextProvider>
        </TrackingWrapper>
      </Router>
    </ThemeProvider>
  );
}

export default App;
