import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  img: {
    width: 70,
    transform: "rotate(-90deg)",
    height: "auto"
  },
  stickerHolder: {
    position: "relative",
    top: 0,
    left: 0,
    width: 50,
    height: 50
    // transform: "rotate(-90deg)",
  },
  claimLabel: {
    width: 50,
    position: "absolute",
    top: 0,
    left: 0,
    height: "auto",
    color: "white",
    paddingRight: 20,
    paddingBottom: 22,
    transform: "rotate(-45deg)",
    zIndex: 20,
    lineHeight: 1
  }
}));
export default function StickerBadge({ imgurl, claim }) {
  const styles = useStyles();
  const imagePath = `/img/${imgurl}`;
  return (
    <div className={styles.stickerHolder}>
      <div className={styles.imgContainer}>
        <img alt={claim} className={styles.img} src={imagePath} />
      </div>
      <Typography
        className={styles.claimLabel}
        variant="body2"
        component="div"
        align="center"
      >
        {claim}
      </Typography>
    </div>
  );
}
