export const numberFormat = (value, locale, currency) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency
  }).format(value);
};

export const getTotalPriceBreakDown = (
  categories,
  variantid = "",
  type = "",
  rebate = null
) => {
  let totalValue = 0;
  let priceCatInfo = [];
  let sumItems = [];
  let cart_items = [];
  let catsCount = {};
  let catsIds = {};
  let catsPrices = {};
  const rebateEligible = rebate !== null && rebate.active;
  categories.forEach((e) => {
    if (e.numTickets > 0) {
      totalValue += e.price;
      if (catsCount[e.name] !== undefined) {
        catsCount[e.name] += e.numTickets;
      } else {
        catsCount[e.name] = [e.numTickets];
        catsIds[e.name] = [e.id];
        catsPrices[e.name] = e.price;
      }
    }
  });
  console.log({ catsCount });
  for (const n in catsCount) {
    if (catsCount.hasOwnProperty(n) && catsCount[n] > 0) {
      priceCatInfo.push(`${catsCount[n]} x ${n}`);
      sumItems.push({
        itemDescription: `${catsCount[n]} x ${n}`,
        itemAmount: `${Number.parseFloat(catsPrices[n]).toFixed(2)}`
      });
      cart_items.push({
        item_name: `${n}`,
        item_id: `${variantid}_${catsIds[n]}`,
        quantity: Number.parseInt(catsCount[n]),
        price: Number.parseFloat(catsPrices[n].toFixed(2))
      });
    }
    //${Number.parseFloat(catsPrices[n]).toFixed(2)}
  }
  if (rebateEligible) {
    totalValue = totalValue - totalValue * (rebate.percentage / 100);
  }
  if (type === "total") {
    return `${numberFormat(totalValue, "de-DE", "EUR")}`;
  } else if (type === "rawTotal") {
    return totalValue;
  } else if (type === "summaryItems") {
    return sumItems;
  } else if (type === "cart_items") {
    return cart_items;
  } else {
    return `${priceCatInfo.join(", ")}`;
  }
};

export const validate = (val, pattern = "") => {
  let regex = null;
  let result;
  switch (pattern) {
    case "email":
      regex = new RegExp(
        /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/gm
      );

      break;
    case "phone":
      regex = new RegExp(/^(\+)*?([0-9\ ]){2,}(\-)*?([0-9\ ]){2,}$/gm);

      break;
    case "name":
      regex = new RegExp(
        /^([A-Za-zÄÖÜßäüö\-\ \.]?){2,}(\ )*?([A-Za-zßäüö\-\ \.]){2,}$/gm
      );

      break;
    default:
      break;
  }
  result = val.match(regex);
  console.log(result);
  if (result !== null && result.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const timeString = (min, hsuffix, msuffix) => {
  const h = Math.floor(min / 60).toFixed(0);
  const m = min % 60;
  return `${h > 0 ? h + hsuffix : ""} ${
    m > 0 && m < 10 ? "0" + m : m > 0 ? m : ""
  } ${m > 0 ? msuffix : ""}`;
};
export const getBestCancellationRule = (rules) => {
  // rules.sort((a, b) => b.refund - a.refund);
  return rules.reduce(
    (acc, rule) => {
      return rule.refund > acc.refund ? rule : acc;
    },
    { period: 0, refund: 0 }
  );
  // return rules[0];
};
