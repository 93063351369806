import { createTheme } from "@material-ui/core/styles";

export const colors = {
  actyDarkBlue: "#172C3D",
  actyLightBlue: "#4196F6",
  actyRed: "#CB016F",
  actyError: "#F43038",
  actyGreen: "#1F990E",
  actyGray: "#BFBEB0",
  actyBackground: "#fdfdff"
};
const fonts = {
  primary: "'Open Sans', sans-serif",
  secondary: "'Days One', sans-serif"
};
// const fonts = {
//   mainTitle: {
//     fontFamily: "'Days One', sans-serif",
//     fontSize: 20,
//   },
//   headline: {
//     fontFamily: "'Open Sans', sans-serif",
//     fontWeight: 500,
//     fontSize: 20,
//     color: colors.actyDarkBlue,
//   },
//   subHeadline: {
//     fontFamily: "'Open Sans', sans-serif",
//     fontWeight: 700,
//     fontSize: 14,
//     color: colors.actyLightBlue,
//   },
//   body: {
//     fontFamily: "'Open Sans', sans-serif",
//     fontWeight: 400,
//     fontSize: 12,
//     color: colors.actyDarkBlue,
//   }
// };
const theme = createTheme({
  palette: {
    primary: {
      main: colors.actyDarkBlue
    },
    secondary: {
      main: colors.actyLightBlue,
      background: colors.actyBackground
    },
    error: {
      main: colors.actyError
    },
    text: {
      primary: colors.actyDarkBlue,
      secondary: "#ffffff"
    }
  },
  props: {
    MuiTypography: {
      gutterBottom: true
    },
    MuiButton: {
      variant: "contained",
      color: "secondary"
    },
    MuiCard: {
      elevation: 6
    },

    MuiToolbar: {
      variant: "dense"
    }
    // MuiContainer: {

    // }
  },
  overrides: {
    MuiButton: {
      label: {
        fontWeight: 600,
        textTransform: "none"
      },
      containedPrimary: {
        backgroundColor: "#2d2d8b",
        "&:hover": {
          backgroundColor: "#14143d"
        }
      },
      outlinedPrimary: {
        boxShadow: "none",
        borderColor: colors.actyGray,
        backgroundColor: "none",
        color: colors.actyGray,
        textTransform: "none",
        "&:active": {
          borderColor: colors.actyGray,
          boxShadow: "none",
          color: colors.actyGray
        },
        "&:hover": {
          borderColor: colors.actyGray,
          boxShadow: "none",
          backgroundColor: colors.actyGray,
          color: "#ffffff"
        },
        "&:focus": {
          borderColor: colors.actyGray,
          boxShadow: "none",
          backgroundColor: colors.actyGray,
          color: "#ffffff"
        }
      }
    },
    MuiImageList: {
      root: {
        flexDirection: "row",
        alignContent: "flex-start"
      }
    },
    // MuiListItemText: {
    //   primary: {
    //     color: colors.actyRed,
    //     fontFamily: fonts.primary,
    //     fontWeight: "bold",
    //     fontSize: "1rem"
    //   }
    // },
    // MuiPaper: {
    //   root: {
    //     padding: 10
    //   },
    //   rounded: {
    //     borderRadius: 10
    //   }
    // },
    MuiIconButton: {
      root: {
        paddingTop: 0,
        marginLeft: 0,
        color: colors.actyLightBlue
      }
    },
    MuiContainer: {
      root: {
        marginTop: 70
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 20,
        marginTop: 0,
        color: colors.actyLightBlue,
        marginRight: 0,
        paddingRight: 0
      }
    }
  },
  shape: {
    borderRadius: 8
  },
  typography: {
    fontFamily: fonts.primary,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,

    h1: {
      fontSize: "1.8rem",
      fontWeight: 500,
      fontFamily: fonts.secondary,
      paddingLeft: 6
    },
    h2: {
      fontFamily: fonts.primary,
      fontSize: "1.1rem",
      fontWeight: 700,
      maxLines: 1,
      lineClamp: 1
    },

    h3: {
      fontSize: "1.4rem",
      fontWeight: 600
    },
    h4: {
      fontSize: "1.2rem",
      fontWeight: 600
    },
    h5: {
      fontSize: "1.0rem",
      fontWeight: 600
    },
    h6: {
      fontSize: "0.9rem",
      fontWeight: 600
    },
    subtitle1: {
      fontSize: "0.9rem",
      color: colors.actyLightBlue,
      fontWeight: 600,
      lineHeight: 1,
      lineClamp: 2
    },
    subtitle2: {
      fontSize: "1rem",
      color: colors.actyLightBlue,
      fontWeight: 600
    },

    caption: {
      fontSize: "0.6rem"
    },
    srOnly: {
      color: colors.actyRed,
      fontWeight: 600
    }
  }
});

export default theme;
