import React from 'react';
import { Container } from '@material-ui/core';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 12,
      paddingLeft: 12
    },
    [theme.breakpoints.up('xs')]: {
      paddingRight: undefined,
      paddingLeft: undefined
    },
    paddingBottom: 12,
    marginBottom: 'auto'
  },
}));

const RootContainer = ({ children, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Container
      {...props}
      className={classes.root}
      // style={{ ...props.style, paddingLeft: padding, paddingRight: padding }}
      maxWidth="lg"
    >
      {children}
    </Container>
  )
}

export default RootContainer;