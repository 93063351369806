import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import ActivityDetailPage from "../pages/ActivityDetailPage";
import qs from "query-string";

const redirects = [
  { path: "/seifenwerkstatt", id: "5f528108d83ccce6d986436c" },
  { path: "/bernsteinschleifen", id: "5d57aa13a1423400115bbf51" },
  { path: "/bernstein-holzschmuck", id: "5f527eacd83ccc1427864366" },
  { path: "/bildhauerwerkstatt", id: "5f527d80d83ccc38a9864361" },
];

export const Redirector = () => {
  const location = useLocation();
  const query = qs.parse(location.search);
  const redirect = redirects.find((item) => {
    return item.path === location.pathname;
  });
  if (redirect && !query.a_id) {
    return <Redirect to={`${redirect.path}/?a_id=${redirect.id}`} />;
  }
  return <ActivityDetailPage />;
};
