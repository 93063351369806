import moment from "moment/min/moment-with-locales";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getActivities } from "../api/activities";
import { useEventsNext, useEventsUpcoming } from "../api/events";

export const EventsContext = createContext();

export const EventsContextProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [timeFrame, setTimeFrame] = useState({ fromDay: 0, days: 2 });
  const { data: upcomingData, loading: upcomingDataLoading, error: upcomingDataError } = useEventsUpcoming(timeFrame);
  const { data: nextData } = useEventsNext();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [moreDataAvailable, setMoreDataAvailable] = useState(true);
  const [noEventsAvailable, setNoEventsAvailable] = useState(false);
  const [checkState, setCheckState] = useState(false);

  useEffect(() => {
    if (upcomingData?.events) {
      if (upcomingData.events.length === 0) {
        setMoreDataAvailable(false);
      } else {
        setNoEventsAvailable(false);
        const missingActivityIds = upcomingData.events
          .filter((event) => !event.activity)
          .map((event) => event.activityId);
        if (missingActivityIds.length > 0) {
          setLoading(true);
          getActivities(i18n.language, { activityIds: missingActivityIds })
            .then((activities) => {
              upcomingData.events
                .filter((event) => !event.activity)
                .map((event) => {
                  event.activity = activities.find((activity) => activity.id === event.activityId);
                  return event;
                });
              const eventsByDay = upcomingData.events
                .map((event) => moment(event.startTime).format("yyyy-MM-DD")) // reduce to day - ignore time
                .filter((uniqueDay, pos, dayOfAllEvents) => dayOfAllEvents.indexOf(uniqueDay) === pos) // filter duplicate days
                .map(
                  (uniqueDays) =>
                    upcomingData.events.filter((event) => moment(event.startTime).format("yyyy-MM-DD") === uniqueDays) // add event data
                );
              setEvents((events) => [...events, ...eventsByDay]);
            })
            .catch((err) => {
              setError(true);
              console.error(err);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    }
  }, [upcomingData, i18n.language]);

  useEffect(() => {
    if (nextData && nextData.days && nextData.days > 0 && !upcomingDataLoading && events.length === 0) {
      if (timeFrame.fromDay <= nextData.days) {
        setTimeFrame({ fromDay: nextData.days + 1, days: 3 });
        setMoreDataAvailable(true);
      }
    }
  }, [nextData, upcomingDataLoading, events, timeFrame]);

  useEffect(() => {
    setLoading(true);
  }, [upcomingDataLoading]);

  useEffect(() => {
    setError(upcomingDataError);
  }, [upcomingDataError]);

  useEffect(() => {
    if (!checkState && events.length === 0) {
      setTimeout(() => setCheckState(true), 5000);
    }
    if (checkState) {
      setNoEventsAvailable(events.length === 0);
    }
  }, [checkState, events.length]);

  const loadNextDay = () => setTimeFrame({ fromDay: timeFrame.fromDay + timeFrame.days, days: 1 });

  return (
    <EventsContext.Provider value={{ events, loadNextDay, loading, error, moreDataAvailable, noEventsAvailable }}>
      {children}
    </EventsContext.Provider>
  );
};
