import React from "react";
import { useParams, useLocation } from "react-router";
import BookingStatus from "../../components/BookingStatus";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useBooking } from "../../api/booking";
export default function PaymentCancelPage() {
  const { id } = useParams();

  const queryParams = new URLSearchParams(useLocation().search);
  const pptoken = queryParams.get("token");

  console.log(pptoken);
  const { booking, bookingLoading, bookingError } = useBooking(id);
  if (bookingLoading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          minWidth: "100%",
          height: "100%",
          minHeight: "100%",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }
  if (bookingError) {
    return <div>ERROR</div>;
  }
  return (
    <BookingStatus
      booking={booking}
      token={pptoken}
      payerId={null}
      paymentCanceled={true}
    />
  );
}
