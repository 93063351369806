import {
  Divider,
  Grid,
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  IconButton
} from "@material-ui/core";
import { Constants } from "@actyvyst/common";
import moment from "moment/min/moment-with-locales";
import { colors } from "../../theme";
import { numberFormat, getTotalPriceBreakDown } from "../../helpers";
import React, { useState } from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import TicketCard from "../TicketCard";
import { useTranslation } from "react-i18next";
import { useVariant } from "../../api/variants";
import { saveAs } from "file-saver";
import config from "../../config";
const { apiPath } = config;
const { BookingStates } = Constants;
const useStyles = makeStyles((theme) => ({
  descriptionText: {
    padding: 0,
    lineHeight: 1
  },
  priceText: {
    fontWeight: "bold",
    paddingTop: 2,
    paddingBottom: 8,
    color: colors.actyRed
  },
  dividerLine: {
    paddingTop: 10,
    paddingBottom: 10
  },
  infoIcon: {
    paddingTop: 0
  },
  iconBtn: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  ticketButton: {
    marginTop: 20
  },
  ticketWrapper: {
    position: "absolute",
    top: 60,
    left: 0,
    flex: 1,
    flexDirection: "column",

    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    height: "auto",
    zIndex: 15
  },
  paymentSucceeded: {
    color: colors.actyGreen
  }
}));
const BookingSummary = ({ booking }) => {
  console.log({ booking });

  const { variant, variantLoading, variantError } = useVariant(
    booking.variant.id
  );
  console.log(variant);
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language);
  const { status } = booking;
  const [open, setOpen] = useState(false);
  const [showTicket, setShowTicket] = useState(false);
  const token =
    booking.paymentIntent?.provider === "paypal"
      ? booking.paymentIntent?.id
      : null;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleTicketShow = () => {
    setShowTicket(!showTicket);
  };
  const handleTicketDownload = () => {
    try {
      saveAs(
        `${apiPath}/activities/bookings/${booking.id}.pdf`,
        "actyvyst-ticket.pdf"
      );
    } catch (err) {
      console.log(err);
      alert("Download failed");
    }
  };
  const statusText = (status) => {
    switch (status) {
      case BookingStates.CONFIRMED:
        return t("Booking.MessageConfirmed");

      case BookingStates.WAITING_FOR_CONFIRMATION:
        return t("Booking.MessagePending");
      case BookingStates.CREATED:
      default:
        return t("Booking.WaitingForFinish");
    }
  };
  //   const Transition = React.forwardRef(function Transition(props, ref) {
  //     return <Slide direction="up" ref={ref} {...props} />;
  //   });
  const paymentMethod =
    token !== null &&
    (status === BookingStates.CONFIRMED ||
      status === BookingStates.WAITING_FOR_CONFIRMATION)
      ? `${t("Payment.PayPal")} (${t("Payment.PayPalId", { tid: token })})`
      : t("Payment.CC");
  //   const transactionID = booking.paymentIntent.provider === "paypal" ? token : undefined;
  return (
    <React.Fragment>
      <div className={styles.ticketWrapper}>
        <TicketCard
          booking={booking}
          showTicket={showTicket}
          closeHandler={handleTicketShow}
          downloadHandler={handleTicketDownload}
        />
      </div>
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="h3">
            {status === BookingStates.CONFIRMED ||
            status === BookingStates.WAITING_FOR_CONFIRMATION
              ? t("Booking.BookingLabel")
              : t("Booking.ReservationLabel")}
          </Typography>
          {status === BookingStates.CONFIRMED ||
          status === BookingStates.WAITING_FOR_CONFIRMATION ? (
            <Grid container direction="row" justifyContent="flex-start">
              <Grid item xs={1} container>
                <CheckCircleOutlineIcon
                  fontSize="medium"
                  className={styles.paymentSucceeded}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body2" className={styles.paymentSucceeded}>
                  {t("Payment.Successful")}
                </Typography>
              </Grid>
            </Grid>
          ) : undefined}
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" component="span">
            {t("Booking.SelectedActivity")}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="body2"
            className={styles.descriptionText}
            component="span"
          >
            {booking.activity.name}
          </Typography>
          <Typography variant="body2">{booking.variant.name}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" component="span">
            {t("Booking.SelectedDate")}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" component="span">
            {`${
              moment(booking.slot.startDate).isSame(
                moment(booking.slot.endDate),
                "day"
              )
                ? moment(booking.slot.startDate).format(t("DateFormatString"))
                : moment(booking.slot.startDate).format(t("DateFormatString")) +
                  " - " +
                  moment(booking.slot.endDate).format(t("DateFormatString"))
            }\n${moment(booking.slot.startDate).format(
              t("TimeFormatString")
            )} - ${moment(booking.slot.endDate).format(
              t("TimeFormatString")
            )} ${t("General.clock")}`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" component="span">
            {t("Booking.SelectedTickets")}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2" component="span">
            {`${getTotalPriceBreakDown(booking.priceBreakdown.categories)}`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" component="span">
            {t("Tickets.ProviderLocation")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" component="span">
            {booking.location.street}
          </Typography>
          <br />
          <Typography variant="body2" component="span">
            {booking.location.zip} {booking.location.city}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" component="span">
            {t("Booking.SelectedProvider")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" component="span">
            {booking.provider.name}
          </Typography>
          <br />
          <Typography variant="body2" component="span">
            {booking.provider.contact.email}
          </Typography>
          <br />
          <Typography variant="body2" component="span">
            {`Tel. ${booking.provider.contact.phone}`}
          </Typography>
        </Grid>
        <Grid item xs={12} className={styles.dividerLine}>
          <Divider />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" component="span">
            {t("Booking.Total")}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="body2"
            component="span"
            className={styles.priceText}
          >
            {numberFormat(booking.totalPrice / 100, "de", "EUR")}
          </Typography>
        </Grid>
        <Grid item xs={12} className={styles.dividerLine}>
          <Divider />
        </Grid>
        {status === BookingStates.CONFIRMED ||
        status === BookingStates.WAITING_FOR_CONFIRMATION ? (
          <React.Fragment>
            <Grid item xs={4}>
              <Typography variant="subtitle1" component="span">
                {t("Payment.SelectedMethod")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" component="span">
                {`${paymentMethod}`}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" component="span">
                {t("Tickets.BookingDateLabel")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" component="span">
                {`${moment(booking.timestamp).format(
                  t("DateTimeFormatString")
                )} ${t("General.clock")}`}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1" component="span">
                {t("Booking.State")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" component="span">
                {statusText(booking.status)}
              </Typography>
            </Grid>
            <Grid item container justifyContent="center" xs={2}>
              {status === BookingStates.WAITING_FOR_CONFIRMATION ? (
                <IconButton
                  onClick={handleClickOpen}
                  className={styles.iconBtn}
                >
                  <InfoOutlinedIcon
                    fontSize="medium"
                    color="secondary"
                    className={styles.infoIcon}
                  />
                </IconButton>
              ) : undefined}
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={6}>
              {booking.status === BookingStates.CONFIRMED ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowTicket(!showTicket)}
                  className={styles.ticketButton}
                >
                  {t("ButtonActions.ViewTicket")}
                </Button>
              ) : undefined}
            </Grid>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="info-dialog-title"
              aria-describedby="info-dialog-description"
            >
              <DialogTitle id="info-dialog-title">
                {t("Offers.BookingTypeOnRequest")}
                <IconButton
                  size="small"
                  style={{ position: "absolute", top: 0, right: 0 }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="info-dialog-description">
                  <Typography
                    variant="body2"
                    component="span"
                    color="primary"
                  >{`${
                    variant !== null ? variant.bookOnRequestInfo : ""
                  }`}</Typography>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </React.Fragment>
        ) : undefined}
      </Grid>
    </React.Fragment>
  );
};
export default BookingSummary;
