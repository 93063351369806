import { useHistory } from "react-router-dom";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Config from "../../config";
import MobileOfferCard from "../OfferCard/MobileOfferCard";
import moment from "moment/min/moment-with-locales";
import OfferCard from "../OfferCard/";
import slugify from "slugify";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const Event = ({ event }) => {
  const { imagePath } = Config;
  const { i18n } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const OfferComponent = isMobile ? MobileOfferCard : OfferCard;
  if (!event.activity) {
    return " "; // maybe activity is not published?
  }
  let timeText = `${moment(event.startTime).locale("de").format("H:mm")} Uhr`;
  if (event.endTime) {
    timeText = `${moment(event.startTime).locale("de").format("H:mm")} bis ${moment(event.endTime).format("H:mm")} Uhr`;
  }
  return (
    <div>
      <div key={event.activity.Id}>
        {renderActivity(
          {
            name: event.description,
            images: event.activity.images,
            id: event.activity.id,
            teaser: `${event.activity.teaser}`,
            subTitle: timeText,
            description: event.activity.description,
            provider: event.activity.provider,
          },
          event.activity.Id
        )}
        <br />
      </div>
    </div>
  );

  function renderActivity(activity, index) {
    const slug = encodeURIComponent(
      slugify(activity.name, {
        lower: true,
        locale: i18n.language,
        strict: true,
      })
    );
    const locationUrl = `/${slug}/?a_id=${activity.id}&pid=${activity.provider.id}`;
    return (
      <OfferComponent
        id={activity.id}
        key={activity.id}
        title={activity.name}
        subTitle={activity.subTitle}
        teaser={activity.teaser}
        description={activity.description}
        image={{
          url: activity.images.length > 0 ? `${imagePath}/${activity.images[0].url}` : "",
          altText: activity.images.length > 0 ? activity.images[0].description : "",
        }}
        isBookable={activity.bookable}
        link={locationUrl}
        onClick={() => {
          window.dataLayer.push({
            event: "selectOffer",
            offerTitle: activity.name,
            offerId: activity.id,
            offerCardPosition: index,
          });
          history.push(locationUrl);
        }}
      />
    );
  }
};
