import React from "react";

import { Container, Button, ButtonGroup, IconButton } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { destination, isWhiteLabel, getProviderId } from "../../util/get-destination";
import constants from "../../constants";
import { useTranslation } from "react-i18next";
import { colors } from "../../theme";
import AndroidDownloadTeaser from "../../components/AndroidDownloadTeaser";
import config from "../../config";
const { destinations } = constants;
const destinationInfo = destinations[destination];
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    marginTop: 0,
    marginBottom: 0,
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingRight: 12,
      paddingLeft: 12,
    },
    [theme.breakpoints.up("xs")]: {
      paddingRight: undefined,
      paddingLeft: undefined,
    },
  },
  appBar: {
    height: 60,
    width: "100%",
    margin: 0,
    backgroundColor: "#FDFDFF",
    color: theme.palette.secondary.main,
    marginBottom: 20,
    marginTop: 0,
  },
  languageSwitch: {
    position: "absolute",
    right: 15,
    top: 16,
    outlinedSecondary: {
      "&:active": {
        boxShadow: "none",
        borderColor: colors.actyDarkBlue,
        backgroundColor: colors.actyLightBlue,
        color: "#ffffff",
      },
      "&:hover": {
        borderColor: colors.actyDarkBlue,
        boxShadow: "none",
        backgroundColor: colors.actyLightBlue,
        color: "#ffffff",
      },
    },
  },
  selectedLanguageButton: {
    fontWeight: "bold",
  },
  deselectedLanguageButton: {
    fontWeight: "normal",
    color: colors.actyGray,
  },

  toolBar: {
    maxHeight: 30,
    backgroundColor: "transparent",
    paddingBottom: 10,
    paddingTop: 8,
    padding: 0,
  },
  wlLogoContainer: {
    maxHeight: 60,
    width: "inherit",
    marginTop: -4,
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  pwrdSubline: {
    fontSize: 10,
    marginTop: -2,
    marginRight: 4,
    color: colors.actyGray,
    textAlign: "right",
  },
  poweredLogo: {
    width: 50,
    height: 50,
    zIndex: 1,
    boxShadow: "1px 3px 4px rgba(20,20,20, 0.3)",
    borderRadius: 8,
  },
  poweredSignContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "end",
    marginTop: 0,
    alignItems: "flex-end",
    paddingRight: 0,
  },
  claim: {
    position: "absolute",
    right: -3,
    bottom: 7,
    transform: "rotate(-45deg)",
    zIndex: 2,
    fontFamily: "Days One",
    fontSize: 9,
    color: "#fff",
  },
}));

const doIrunInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const PoweredBySign = () => {
  const classes = useStyles();
  const iFrameActive = doIrunInIframe();

  if (isWhiteLabel === true) {
    return (
      <Container className={classes.poweredSignContainer}>
        {iFrameActive ? (
          <IconButton
            style={{ padding: 12 }}
            disableRipple
            onClick={(e) => {
              e.preventDefault();
              window.parent.postMessage({ action: "close" }, "*");
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : undefined}
        <img src={`/wl/img/${destination}.png`} alt="powered by actyvyst GmbH" className={classes.poweredLogo} />
        <div className={classes.pwrdSubline}>
          powered by
          <br /> actyvyst
        </div>
      </Container>
    );
  }
  return null;
};

const HeaderImage = ({ history, currentLanguage, wl }) => {
  const providerId = getProviderId(window.location.href);
  const classes = useStyles();

  if (wl === true) {
    return (
      <div className={classes.wlLogoContainer}>
        <img
          src={`${config.wlLogoPath}/${providerId}.png`}
          alt="actyvyst Partner"
          style={{ maxHeight: 50, objectFit: "contain" }}
        />
      </div>
    );
  } else {
    return (
      <img
        alt={`actyvyst ${destination} Logo`}
        src={`/img/logo_actyvyst_${destination}_${currentLanguage}.png`}
        style={{
          objectFit: "contain",
          maxHeight: 38,
          // maxWidth: 220,
          cursor: "pointer",
          marginTop: destinationInfo.logoOffset,
        }}
        onClick={() => {
          history.push("/");
        }}
      />
    );
  }
};

const Header = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    if (destinationInfo.languages.find((l) => lng === l)) localStorage.setItem("language", lng);
    window.location.reload();
  };
  return (
    <React.Fragment>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Container className={classes.root} maxWidth="lg">
            <HeaderImage currentLanguage={currentLanguage} history={history} wl={isWhiteLabel} />
            <PoweredBySign />
            {/* {process.env.REACT_APP_DEPLOYMENT_TARGET !== "prod" ? (
              <p style={{ marginRight: "150px" }}>
                {process.env.REACT_APP_DEPLOYMENT_TARGET}
              </p>
            ) : undefined} */}

            {destinationInfo.languages.length > 1 ? (
              <ButtonGroup
                size="small"
                variant="outlined"
                color="secondary"
                disableFocusRipple={true}
                disableRipple={true}
                className={classes.languageSwitch}
              >
                {destinationInfo.languages.map((lng) => {
                  return (
                    <Button
                      onClick={() => changeLanguage(lng)}
                      className={
                        currentLanguage === lng ? classes.selectedLanguageButton : classes.deselectedLanguageButton
                      }
                    >
                      {lng}
                    </Button>
                  );
                })}
              </ButtonGroup>
            ) : undefined}
            <AndroidDownloadTeaser />
          </Container>
        </Toolbar>
      </AppBar>
      {/* <div style={{ backgroundColor: "yellowgreen" }}>
        Environment: {process.env.REACT_APP_DEPLOYMENT_TARGET}
      </div> */}
    </React.Fragment>
  );
};

export default Header;
