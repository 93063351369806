import { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import "./index.css";

const useStyles = makeStyles((theme) => ({
  backIcon: {
    color: "inherit",
    fontSize: "inherit",
    verticalAlign: "middle",
  },
  forwardIcon: {
    color: "inherit",
    fontSize: "inherit",
    verticalAlign: "middle",
  },
}));

const MainNavLink = ({ onClick, text, position = "left" }) => {
  const { isInitialPage } = useContext(AppContext);
  const history = useHistory();
  const classes = useStyles();
  const defaultHandler = function () {
    if (isInitialPage) {
      history.push("/");
    } else {
      history.goBack();
    }
  };
  const flexDirection = position === "right" ? "flex-end" : "flex-start";
  return (
    <div
      style={{
        display: "flex",
        justifyContent: flexDirection,
        width: "100%",
      }}
    >
      <a
        href="/"
        className="main-nav-link"
        onClick={(e) => {
          e.preventDefault();
          if (onClick) {
            onClick();
          } else {
            defaultHandler();
          }
        }}
      >
        {position === "left" && <ArrowBackIos className={classes.backIcon} />}
        {text}
        {position === "right" && <ArrowForwardIos className={classes.forwardIcon} />}
      </a>
    </div>
  );
};

export default MainNavLink;
