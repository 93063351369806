const constants = {
  destinations: {
    sylt: {
      name: "Sylt",
      appstoreId: "1465082431",
      playstoreId: "com.actyvystsylt",
      languages: ["de"],
      logoOffset: 0
    },
    ruegen: {
      name: "Rügen",
      appstoreId: "1550101140",
      playstoreId: "com.actyvyst.ruegen",
      languages: ["de"],
      logoOffset: 4
    },
    mallorca: {
      name: "Mallorca",
      appstoreId: "1565782272",
      playstoreId: "com.actyvyst.mallorca",
      languages: ["en", "es", "de"],
      logoOffset: 6
    },
    texel: {
      name: "Texel",
      appstoreId: "",
      playstoreId: "",
      languages: ["en", "nl", "de"]
    },
    allgaeu: {
      name: "Allgäu",
      appstoreId: "",
      playstoreId: "",
      languages: ["de"]
    }
  }
};

export default constants;
