import React, { useState, useContext, useRef } from "react";
import { Menu, IconButton, Button, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import { isWhiteLabel } from "../../util/get-destination";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CategoryList from "./CategoryList";
import { useLocation, useHistory } from "react-router-dom";
import { ActivityContext } from "../../context/ActivitiesContext";
import { useTranslation } from "react-i18next";

// import theme from '../../theme';

const useStyles = makeStyles((theme) => ({
  listItem: {
    height: 36,
    maxHeight: 36,
  },
  checkbox: {
    color: undefined,
  },
  button: {
    width: "100%",
    minWidth: "100%",
    marginTop: 8,
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 12,
    marginLeft: "auto",
  },
}));

const HeaderFilterBox = () => {
  const anchorRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { categories, filters } = useContext(ActivityContext);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(filters.categories);
  const { t, i18n } = useTranslation();
  const handleOpen = (event) => {
    setAnchorEl(anchorRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (value) => {
    if (selected.indexOf(value) > -1) {
      setSelected(selected.filter((item) => item !== value));
    } else {
      setSelected([...selected, value]);
    }
  };

  const displayFilter = () => {
    if (selected.length !== filters.categories.length) {
      return true;
    }
    for (const cat of filters.categories) {
      if (selected.findIndex((item) => cat === item) < 0) {
        return true;
      }
    }
    return false;
  };
  const displayReset = () => {
    return selected.length > 0;
  };

  const applyFilters = () => {
    const path = `${location.pathname}?categories=${selected.join(",")}`;
    history.push(path);
  };
  const resetFilters = () => {
    history.push(location.pathname);
  };
  const selectedCategoryNames = categories
    .filter((item) => filters.categories.findIndex((f) => f === item.id) > -1)
    .map((cat) => cat.name);
  // console.log(selectedCategoryNames);
  if (isWhiteLabel) {
    return null;
  } else {
    return (
      <div
        style={{
          paddingLeft: 12,
          borderLeftColor: theme.palette.secondary.main,
          borderLeftStyle: "solid",
          borderLeftWidth: 1,
          display: "flex",
          // width: '100%'
        }}
      >
        {/* <div> */}
        <Typography style={{ cursor: "pointer", textDecoration: "underline" }} onClick={handleOpen} noWrap>
          {filters.categories.length > 0 ? selectedCategoryNames.join(", ") : t("General.Filter")}
        </Typography>
        {/* </div>
      <div style={{ marginLeft: 'auto' }}> */}

        <IconButton style={{ marginLeft: "auto" }} onClick={handleOpen} size="small" ref={anchorRef}>
          <ExpandMoreIcon />
        </IconButton>
        {/* </div> */}

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          style={{ margin: 0, maxWidth: "100%" }}
        >
          <CategoryList categories={categories} selected={selected} onToggle={handleToggle} />

          <div className={classes.button}>
            <Button
              disabled={displayReset() === true ? false : true}
              style={{ width: "100%", marginLeft: 12, marginRight: 6 }}
              variant="contained"
              size="small"
              color="secondary"
              onClick={resetFilters}
            >
              {t("General.FilterAll")}
            </Button>
            <Button
              disabled={displayFilter() === true ? false : true}
              style={{ width: "100%", marginLeft: 6, marginRight: 12 }}
              variant="contained"
              size="small"
              color="secondary"
              onClick={applyFilters}
            >
              {t("General.ApplyFilter")}
            </Button>
          </div>
          <div className={classes.button}></div>
          <div className={classes.button}>
            <Button style={{ width: "100%" }} variant="text" size="small" color="secondary" onClick={handleClose}>
              {t("ButtonActions.Cancel")}
            </Button>
          </div>
        </Menu>
      </div>
      // </FormControl>
    );
  }
};

export default HeaderFilterBox;
