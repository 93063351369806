import React, { useState } from "react";

import { Grid, ImageList, Typography, makeStyles } from "@material-ui/core";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";

const useStyles = makeStyles((theme) => ({
  mainImage: {
    maxHeight: 400,
    minHeight: 400,
    width: "100%",
    paddingRight: 10,
    objectFit: "cover"
  },
  mainImageTitleBar: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    position: "relative",
    bottom: 28,
    left: 0,
    height: 20,
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3
  },
  titleBar: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    height: 20
  },
  titleBarLine: {
    fontSize: 12
  },
  imageList: {
    width: "100%",
    height: "auto",
    // Promote the list into its own layer in Chrome. This cost memory, but helps keep FPS high.
    transform: "translateZ(0)"
  },
  mobileImageList: {
    width: "100%",
    height: "auto",
    flexWrap: "nowrap",
    // Promote the list into its own layer in Chrome. This cost memory, but helps keep FPS high.
    transform: "translateZ(0)"
  },
  imageItem: {
    objectFit: "cover",
    cursor: "pointer",
    marginLeft: 1
  }
}));

export default function ImageGallery({ images, isMobile }) {
  const classes = useStyles();

  const [mainImage, setMainImage] = useState(0);
  const imgGrids = images.length > 1 ? [10, 2] : [12, 0];
  return (
    <Grid container>
      {isMobile ? undefined : (
        <Grid
          item
          xs={imgGrids[0]}
          style={{ borderLeft: 4, borderColor: "white" }}
        >
          <img
            src={images[mainImage]?.url || "/"}
            className={classes.mainImage}
            alt={images[mainImage]?.description || ""}
          />
          <div className={classes.mainImageTitleBar}>
            <Typography
              variant="body2"
              className={classes.titleBarLine}
              color="textSecondary"
            >
              {images[mainImage]?.description || ""}
            </Typography>
          </div>
        </Grid>
      )}

      {imgGrids[1] === 0 && !isMobile ? undefined : (
        <Grid item container xs={isMobile ? 12 : imgGrids[1]}>
          <ImageList
            gap={1}
            className={isMobile ? classes.mobileImageList : classes.imageList}
            cols={1}
            // rowHeight={isMobile ? "auto" : "none"}
            // style={{ maxHeight: 400, minHeight: 400 }}
          >
            {images.map((img, i) => (
              <ImageListItem
                key={img.url}
                cols={1}
                rows={isMobile ? 1.5 : 0.5}
                className={classes.imageItem}
                onClick={() => {
                  setMainImage(i);
                }}
              >
                <img src={img.url} alt={img.description} />
                {!isMobile ? undefined : (
                  <ImageListItemBar
                    title={img.description}
                    position="bottom"
                    classes={{
                      root: classes.titleBar,
                      title: classes.titleBarLine
                    }}
                  />
                )}
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      )}
    </Grid>
  );
}
