import { ButtonBase, Grid, Typography, makeStyles } from "@material-ui/core";
import { colors } from "../../theme";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  ppButton: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.actyLightBlue,
    marginTop: 28,

    borderStyle: "solid",
    padding: 20
  }
}));
const PaypalPayment = ({ approveUrl }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <Grid container>
      <Grid item xs={4}></Grid>
      <Grid item xs={8}>
        <Typography variant="body2" component="span">
          {t("Payment.PayPalInfo")}
        </Typography>
        <br />
        <ButtonBase
          className={styles.ppButton}
          onClick={() => {
            window.open(approveUrl, "_self");
          }}
        >
          <img
            src="https://www.paypalobjects.com/webstatic/de_DE/i/de-pp-logo-150px.png"
            alt={t("Payment.ppClaim")}
          />
        </ButtonBase>
      </Grid>
    </Grid>
  );
};

export default PaypalPayment;
