import { useState } from "react";
import { Card, CardMedia, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import LazyLoad from "react-lazyload";
import StickerBadge from "../Sticker";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // maxHeight: 160,
    cursor: "pointer",
  },
  contentSection: {
    maxHeight: 28,
  },
  cardHeader: {
    marginTop: -10,
    paddingTop: 0,
    marginBottom: 4,
    lineHeight: 1,
    textOverflow: "ellipsis",
  },
  hiddenAnchor: {
    textDecoration: "none",
    color: "inherit",
    "&:visited": {
      color: "inherit",
    },
  },
  cardSubTitle: {
    marginTop: 0,
    paddingTop: -6,
    lineHeight: 1.3,
    overflow: "hidden",
    maxWidth: "98%",
    textOverflow: "ellipsis",

    whiteSpace: "nowrap",
  },
}));

const MobileOfferCard = ({ id, image, title, description, teaser, onClick, destination, isBookable, link }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isActive, setIsActive] = useState(false);
  const { t } = useTranslation();
  return (
    <LazyLoad height={160} once>
      <Card
        onMouseOver={() => {
          setIsActive(true);
        }}
        onMouseOut={() => {
          setIsActive(false);
        }}
        className={classes.root}
        // elevation={isActive ? 16 : 3}
        onClick={() => onClick()}
      >
        <CardMedia
          component="div"
          alt={image.altText}
          image={image.url}
          title={image.altText}
          style={{ minHeight: 200 }}
        >
          {isBookable ? <StickerBadge imgurl="offerbadge.png" claim={t("Tickets.TeaserBadge")} /> : undefined}
        </CardMedia>
        <CardContent className={classes.contentSection}>
          <Typography variant="h4" className={classes.cardHeader}>
            <Link
              className={classes.hiddenAnchor}
              to={link}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {title}
            </Link>
          </Typography>
          <Typography variant="subtitle1" color="secondary" className={classes.cardSubTitle}>
            {teaser}
          </Typography>
        </CardContent>
      </Card>
    </LazyLoad>
  );
};

export default MobileOfferCard;
