import { useEffect, createContext, useState } from "react";
import { useActivities, useCategories } from "../api/activities";
import { useLocation } from 'react-router-dom';
import qs from 'query-string';


export const ActivityContext = createContext();

export const ActivityContextProvider = ({ children }) => {
  const location = useLocation();
  const [filters, setFilters] = useState({
    categories: []
  });
  const { activities, activitiesLoading, activitiesError, retry: retryActivities } =
    useActivities({ ...filters });
  const { categories, categoriesLoading, categoriesError, retry: retryCategories } = useCategories();



  const reload = () => {
    retryActivities();
    retryCategories();
  }


  return (
    <ActivityContext.Provider
      value={{
        activities,
        // activitiesLoading,
        // activitiesError,
        categories,
        // categoriesLoading,
        // categoriesError,
        loading: activitiesLoading || categoriesLoading,
        error: activitiesError || categoriesError,
        setFilters,
        reload,
        // setFilters,
        filters
      }}
    >
      {children}
    </ActivityContext.Provider>
  );
};
