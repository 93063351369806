import { EventsList } from "../components/Events";
import MainNavLink from "../components/MainNavLink";
import { useTranslation } from "react-i18next";

export default function EventsUpcomingPage() {
  const { t } = useTranslation();

  return (
    <div>
      <MainNavLink to="/" text={t("NavigationTitles.BackToActivityList")} />
      <EventsList />
    </div>
  );
}
