import React, { useState } from "react";
import {
  Paper,
  Typography,
  Grid,
  Button,
  makeStyles,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CalendarIcon from "../CalendarIcon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import GroupIcon from "@material-ui/icons/Group";
import moment from "moment/min/moment-with-locales";
import ParticipantsPanel from "./ParticipantsPanel";
import config from "../../config";
import { colors } from "../../theme";
import { useTranslation } from "react-i18next";
const { weekdays } = config;

const useStyles = makeStyles((theme) => ({
  panelHeadline: {
    color: colors.actyLightBlue,
    fontWeight: "bold"
  },
  background: {
    width: "100%",
    minWidth: "100%",
    height: "100%",
    padding: "18px 0px 18px 0px"
  },
  specText: {
    marginLeft: 4,
    fontSize: "0.9em",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  detailsBtn: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.7em"
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "0.8em"
    },

    marginRight: 6,

    whiteSpace: "nowrap"
  }
}));

const SlotCard = ({
  variant,
  slot,
  active,
  setActive,
  selectedTickets,
  onChangeTickets,
  onProceedToBooking
}) => {
  const dayOfMonth = moment(slot.startDate).date();
  const dayOfWeek = moment(slot.startDate).day();
  const month = moment(slot.startDate).month();
  const year = moment(slot.startDate).year();
  const { priceCategories } = variant;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language);
  // const [ticketCount, setTicketCount] = useState(priceCategories.map((category) => 0));
  // const onChangeTickets = (index, increment) => {
  //   const val = ticketCount[index] + increment;
  //   if (val >= 0 && val <= slot.availableSlots) {
  //     setTicketCount([...ticketCount.slice(0, index), val, ...ticketCount.slice(index + 1, ticketCount.length)])
  //   }
  // }
  const slotDatesToTimeStrings = () => {
    const slotTimeStrings = {};
    if (moment(slot.startDate).isSame(moment(slot.endDate), "day")) {
      slotTimeStrings.slotDate = `${moment(slot.startDate).format(
        t("DateFormatString")
      )}`;
      slotTimeStrings.slotTimes = `${moment(slot.startDate).format(
        "HH:mm"
      )} - ${moment(slot.endDate).format("HH:mm")} ${t("General.clock")}`;
    } else {
      slotTimeStrings.slotDate = `${
        moment(slot.startDate).format(t("DateFormatString")) +
        " - " +
        moment(slot.endDate).format(t("DateFormatString"))
      } `;
      slotTimeStrings.slotTimes = `${t("General.daily")} ${moment(
        slot.startDate
      ).format("HH:mm")} - ${moment(slot.endDate).format("HH:mm")} ${t(
        "General.clock"
      )}`;
    }
    return slotTimeStrings;
  };
  const selectedParticipants = priceCategories.reduce((acc, cur, index) => {
    return acc + cur.allowedParticipants * selectedTickets[index];
  }, 0);

  return (
    <Paper className={classes.background}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={2}>
          <CalendarIcon day={weekdays[dayOfWeek]} />
        </Grid>
        <Grid item xs={7}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography color="primary" className={classes.panelHeadline}>
              {slotDatesToTimeStrings().slotDate}
            </Typography>
            <Grid
              container
              direction="row"
              alignContent="center"
              alignItems="center"
            >
              <Grid item xs={1}>
                <AccessTimeIcon
                  fontSize="inherit"
                  style={{ color: "gray", fontSize: 14 }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body2" className={classes.specText}>
                  {slotDatesToTimeStrings().slotTimes}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <GroupIcon
                  fontSize="inherit"
                  style={{ color: "gray", fontSize: 14 }}
                />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body2" className={classes.specText}>
                  {slot.availableSlots > 0
                    ? `${slot.availableSlots - selectedParticipants} / ${
                        slot.maxParticipants
                      } ${t("Offers.freePlace")}`
                    : `${t("Offers.BookedOut")}`}
                  {}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          container
          alignContent="flex-end"
          justifyContent="flex-end"
        >
          <Button
            color="secondary"
            onClick={setActive}
            className={classes.detailsBtn}
            disabled={slot.availableSlots - selectedParticipants <= 0}
          >
            {t("ButtonActions.ChooseSlot")}
            {active ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </Button>
        </Grid>
        {active ? (
          <React.Fragment>
            <Grid
              container
              direction="row"
              alignContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12}>
                <ParticipantsPanel
                  variant={variant}
                  slot={slot}
                  selectedTickets={selectedTickets}
                  onChangeTickets={onChangeTickets}
                  isMobile={isMobile}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Grid item xs={9}></Grid>
              <Grid
                item
                xs={3}
                container
                alignContent="flex-end"
                justifyContent="flex-end"
              >
                <Button
                  color="secondary"
                  disabled={selectedParticipants <= 0}
                  onClick={onProceedToBooking}
                  className={classes.detailsBtn}
                >
                  {t("ButtonActions.GoBooking")}
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : undefined}
      </Grid>
    </Paper>
  );
};
export default SlotCard;
