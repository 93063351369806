import React from "react";
import { useParams } from "react-router";
import VariantDetails from "../components/VariantDetails";
import { useSlots } from "../api/slots";
import { useVariant } from "../api/variants";
import LoadingIndicator from "../components/LoadingIndicator";

export default function VariantDetailPage() {
  const { id } = useParams();

  console.log(id);
  const { data, error } = useSlots(id);
  const { variant, variantLoading, variantError } = useVariant(id);
  if (!data || variantLoading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          minWidth: "100%",
          height: "100%",
          minHeight: "100%",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }
  if (error || variantError) {
    return <div>ERROR</div>;
  }
  return <VariantDetails slots={data} variant={variant} />;
}
