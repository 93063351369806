import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { CircularProgress, Typography } from "@material-ui/core";
import { Grid, TextField } from "@material-ui/core";
import { colors } from "../../theme";

import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "default",
  hidePostalCode: true,
  style: {
    base: {
      color: colors.actyDarkBlue,
      fontFamily: '"Open Sans", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "1.1em",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: colors.actyLightBlue,
      "::placeholder": {
        color: colors.actyGray
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

const StripeCardFields = ({ onChange }) => {
  return (
    <div
      style={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: colors.actyGray,
        padding: 12,
        borderRadius: 10,
        marginTop: 10,
        marginBottom: 10
      }}
    >
      <label>
        <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onChange} />
      </label>
    </div>
  );
};

const StripePayment = ({
  clientSecret,
  onComplete,
  holderName,
  errorCreateHandler
}) => {
  return (
    <Elements stripe={stripePromise}>
      <StripePaymentModule
        clientSecret={clientSecret}
        onComplete={onComplete}
        cardHolderName={holderName}
        errorCreateHandler={errorCreateHandler}
      />
    </Elements>
  );
};

const StripePaymentModule = ({
  clientSecret,
  onComplete,
  cardHolderName,
  errorCreateHandler
}) => {
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const [ccHolder, setCcHolder] = useState(cardHolderName);

  return (
    <Grid container>
      <Grid item xs={0} sm={4}></Grid>
      <Grid item xs={12} sm={8}>
        <Typography variant="h6" color="secondary">
          {t("Payment.CCHeadline")}
        </Typography>
      </Grid>
      <Grid item xs={0} sm={4}></Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          margin="dense"
          variant="outlined"
          value={cardHolderName}
          label={t("Payment.CardHolder")}
          placeholder={t("Payment.CardHolder")}
          style={{
            width: "100%",
            fontSize: 12,
            "& input::placeholder": {
              fontSize: "0.8em"
            }
          }}
          InputLabelProps={{
            shrink: true,
            style: { color: colors.actyLightBlue }
          }}
          onChange={(e) => setCcHolder(e.target.value)}
        />
        <StripeCardFields
          onChange={(event) => {
            setPaymentComplete(event.complete);
          }}
        />
      </Grid>
      <Grid item xs={0} sm={4}></Grid>
      <Grid
        item
        xs={12}
        sm={8}
        container
        direction="column"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        {" "}
        <Button
          disabled={paymentComplete && !loading ? false : true}
          onClick={() => {
            const pay = async () => {
              setLoading(true);
              const { paymentIntent, error } = await stripe.confirmCardPayment(
                clientSecret,
                {
                  payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                      name: ccHolder
                    }
                  }
                }
              );
              if (paymentIntent) {
                setLoading(false);
                onComplete();
              }
              if (error) {
                setLoading(false);
                errorCreateHandler({
                  title: t("PaymentErrors.PaymentErrorTitle"),
                  message: t(`PaymentErrors.${error.code}`)
                });
              }
            };

            if (paymentComplete === true) {
              pay();
            }
          }}
        >
          {loading ? (
            <CircularProgress size={20} color="secondary" />
          ) : (
            t("ButtonActions.PayNow")
          )}
        </Button>
      </Grid>

      {/* {JSON.stringify(stripeEvent)} */}
    </Grid>
  );
};

export default StripePayment;
