import { useState, useEffect } from "react";
import createFetcher from "./fetcher";
import config from "../config";
import _ from "lodash";
import { destination } from "../util/get-destination";
import { useTranslation } from "react-i18next";

const { apiPath } = config;

function useData(path, query) {
  const { i18n } = useTranslation();
  const url = `${apiPath}${path}`;
  const [data, setData] = useState(null);
  const [queryUsed, setQueryUsed] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false);
  const fetcher = createFetcher({ destination, language: i18n.language });

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await fetcher(url, { params: query });
        setQueryUsed(query);
        setData(response);
      } catch (err) {
        console.log(err);
        setData(null);
        setError(err.message);
      } finally {
        setLoading(false);
        setShouldUpdate(false);
        setIsInitialized(true);
      }
    };
    if (shouldUpdate) {
      setShouldUpdate(false);
      getData();
    }
  }, [shouldUpdate]);

  useEffect(() => {
    setShouldUpdate(true);
    setIsInitialized(false);
  }, [url]);

  useEffect(() => {
    if (!_.isEqual(query, queryUsed)) {
      setShouldUpdate(true);
      setIsInitialized(false);
    }
  }, [query]);

  const retry = () => {
    setShouldUpdate(true);
  };

  return {
    data,
    loading,
    error,
    retry,
    isInitialized,
  };
}

const getData = (path, language, query) => {
  const fetcher = createFetcher({ destination, language: language });
  const url = `${apiPath}${path}`;
  return fetcher(url, { params: query });
};

export { useData, getData };
