import RootContainer from "../containers/RootContainer";
import Header from "../containers/Header";
import Footer from "../containers/Footer";
import React from "react";

const MainLayout = ({ children }) => {
  return (
    <React.Fragment>
      <div style={{ height: "100%", minHeight: "100vh", margin: 0, display: "flex", flexDirection: "column" }}>
        <RootContainer>
          <Header />

          {children}
        </RootContainer>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default MainLayout;
