import axios from "axios";
import { Deserializer } from "jsonapi-serializer";
import constants from "../constants";
import { destination, isWhiteLabel, getProviderId } from "../util/get-destination";
// import { useTranslation } from "react-i18next"
const deserializer = new Deserializer({ keyForAttribute: "camelCase" });
const { destinations } = constants;

const createFetcher = ({ destination = "sylt", language = "de" }) => {
  return async (url, options) => {
    axios.defaults.params = {
      destination: destination,
      language: language,
      provider: isWhiteLabel === true ? getProviderId(window.location.href) : null,
    };
    try {
      // console.log("Language: ", language);
      // console.log(axios.defaults.params);
      // console.log("Fetcher URL", url, options.params);
      const response = await axios.get(url, options);
      const data = await deserializer.deserialize(response.data);
      // console.debug(data);
      return data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
};

export default createFetcher;
