import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  makeStyles,
  Grid,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { numberFormat } from "../../helpers";
import DownloadDialog from "../DownloadDialog/index.js";
import {
  destination,
  isWhiteLabel,
  getProviderId,
} from "../../util/get-destination";
import { colors } from "../../theme";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  gridContainer: { padding: 0, margin: 0 },
  offerListItem: {
    color: colors.actyDarkBlue,
    fontWeight: "bold",
    fontFamily: theme.typography.fontFamily,

    fontSize: "0.9rem",
  },
  subListItem: {
    marginRight: 10,
    color: colors.actyDarkBlue,
  },
  offerBtn: {
    color: "white",
    backgroundColor: colors.actyRed,
    "&:hover": {
      backgroundColor: colors.actyRed,
    },
    width: "100%",
    height: 40,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 6,
    },
  },
  btnIcon: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const OfferList = ({ content, isMobile, isMedium, onSelectItem }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  const list = content.map((item, index) => {
    console.log({ item });
    return (
      <ListItem key={`offer_${index}`}>
        <Grid container className={classes.gridContainer}>
          <Grid container item xs={7} className={classes.gridContainer}>
            <ListItemText
              primary={item.name}
              secondary={item.description}
              classes={{
                primary: classes.offerListItem,
                secondary: classes.subListItem,
              }}
            />
          </Grid>
          <Grid container item xs={5} className={classes.gridContainer}>
            <Button
              secondary
              onClick={() => {
                //   alert(
                //     "Download-Links: Rügen https://apps.apple.com/de/app/actyvyst/id1550101140, Sylt https://apps.apple.com/de/app/actyvyst/id1465082431, Malle https://apps.apple.com/de/app/actyvyst/id1565782272"
                //   );

                //    handleClickOpen();
                onSelectItem(item);
              }}
              className={classes.offerBtn}
            >
              {item.displayOnly
                ? t("Offers.LinkOnly")
                : t("Offers.offersFrom", {
                    price: numberFormat(
                      item.displayPrice,
                      i18n.languages[0],
                      "EUR"
                    ),
                  })}
              <ChevronRightIcon className={classes.btnIcon} />
            </Button>
          </Grid>
        </Grid>
      </ListItem>
    );
  });
  return (
    <React.Fragment>
      <List>{list}</List>
      {/* <DownloadDialog
        open={open}
        handleClose={handleClose}
        destination={destination}
        isMobile={isMobile}
        isMedium={isMedium}
      /> */}
    </React.Fragment>
  );
};

export default OfferList;
