import { useData, getData } from "./useData";

const activitiesPath = "/activities";

const getActivitiesQuery = (filters) => {
  const query = {};
  if (filters.categories && filters.categories.length > 0) {
    query.category_id = filters.categories.join(",");
  }
  if (filters.destination) {
    query.destination = filters.destination;
  }
  if (filters.activityIds?.length > 0) {
    query["filter[ids]"] = filters.activityIds.join(",");
  }
  return query;
};

function useActivities(filters) {
  const query = getActivitiesQuery(filters);
  const { data, loading, error, retry } = useData(activitiesPath, query);
  return {
    activities: data || [],
    activitiesLoading: loading,
    activitiesError: error,
    retry,
  };
}

function getActivities(language, filters) {
  const query = getActivitiesQuery(filters);
  return getData(activitiesPath, language, query);
}

function useCategories() {
  const { data, loading, error, retry } = useData("/activities/categories");
  return {
    categories: data || [],
    categoriesLoading: loading,
    categoriesError: error,
    retry,
  };
}

function useExternalIBEs(id) {
  const { data, loading, error, retry } = useData(`/activities/${id}/external-ibe-configs`);
  return {
    data: data || [],
    loading,
    error,
    retry,
  };
}

function useActivity(id) {
  const { data, loading, error, retry } = useData(`/activities/${id}`);
  return {
    activity: data,
    activityLoading: loading,
    activityError: error,
    retry,
  };
}

function useActivityEventsToday() {
  const { data, loading, error, retry } = useData("/activities/events/today");
  return {
    data: data || null,
    loading,
    error,
    retry,
  };
}

export { useActivities, useCategories, useActivity, useExternalIBEs, useActivityEventsToday, getActivities };
