import { useTranslation } from "react-i18next";
export const Whoops = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h1>Whoops!</h1>
      <p>{t("ErrorView.WentWrong")}</p>
    </div>
  );
};
