import moment from "moment/min/moment-with-locales";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Event } from "./Event";

export const EventsDayContainer = ({ events }) => {
  const [day, setDay] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const getDayLeftText = (day) => {
      const text = moment(day).locale("de").format("dddd DD.MM.YYYY");
      const doyToday = moment().dayOfYear();
      const doyDay = moment(day).dayOfYear();
      const days = doyDay - doyToday;
      const inDaysText = t("Events.InDays", { days });
      switch (days) {
        case 0:
          return `${t("Home.EventsToday")} - ${text}`;
        case 1:
          return `${t("Home.EventsTomorrow")} - ${text}`;
        case 2:
          return `${t("Home.EventsAfterTomorrow")} - ${text}`;
        default:
          return `${text} - ${inDaysText}`;
      }
    };
    if (events && events.length > 0 && events[0].startTime) {
      const day = getDayLeftText(events[0].startTime);
      setDay(day);
    }
  }, [events, t]);

  return (
    <div>
      <h3>{day}</h3>
      {events.map((event, index) => (
        <Event key={index} event={event} />
      ))}
    </div>
  );
};
