import React, { useState, createContext, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

export const AppContext = createContext();

export const AppContextProvider = (props) => {
  const [isInitialPage, setIsInitialPage] = useState(true);
  const history = useHistory();
  useEffect(() => {
    return history.listen(({ action, location }) => {
      setIsInitialPage(false);
    });
  }, []);
  return (
    <AppContext.Provider
      value={{
        isInitialPage,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
