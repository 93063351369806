import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationDE from "./de.json";
import translationEN from "./en.json";
import translationES from "./es.json";
import constants from "../constants";
import { destination } from "../util/get-destination";
const { destinations } = constants;
const destinationInfo = destinations[destination];
const resources = {
  de: {
    translation: translationDE
  },
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  }
};
const savedLanguage = localStorage.getItem("language");
const language = destinationInfo.languages.find((item) => item === savedLanguage) || destinationInfo.languages[0];
if (language !== savedLanguage) {
  localStorage.setItem('language', language);
}
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,

    keySeparator: ".", // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
