import { Switch, Redirect } from "react-router-dom";
import RouteWithLayout from "./RouteWithLayout";
import { ActivityContextProvider } from "../context/ActivitiesContext";
import ActivitiesPage from "../pages/ActivitiesPage";
import ActivityDetailPage from "../pages/ActivityDetailPage";
import VariantDetailPage from "../pages/VariantDetailPage";
import BookingFormPage from "../pages/booking/BookingFormPage";
import PaymentReturnPage from "../pages/booking/PaymentReturnPage";
import PaymentCancelPage from "../pages/booking/PaymentCancelPage";
import EventsUpcomingPage from "../pages/EventsUpcomingPage";
import { EventsContextProvider } from "../context/EventsContext";
import { Redirector } from "./Redirector";

const MainRoutes = () => {
  return (
    <ActivityContextProvider>
      <EventsContextProvider>
        <Switch>
          <RouteWithLayout exact path="/">
            <ActivitiesPage />
          </RouteWithLayout>
          <RouteWithLayout exact path="/events/upcoming">
            <EventsUpcomingPage />
          </RouteWithLayout>
          <RouteWithLayout path="/variants/:id">
            <VariantDetailPage />
          </RouteWithLayout>
          <RouteWithLayout exact path="/bookings/:id">
            <BookingFormPage />
          </RouteWithLayout>
          <RouteWithLayout exact path="/booking/paypalreturn/:id">
            <PaymentReturnPage />
          </RouteWithLayout>
          <RouteWithLayout exact path="/booking/paypalcancel/:id">
            <PaymentCancelPage />
          </RouteWithLayout>
          <RouteWithLayout exact path="/booking/ccconfirmed/:id">
            <PaymentReturnPage />
          </RouteWithLayout>
          <RouteWithLayout exact path="/seifenwerkstatt">
            <Redirector />
          </RouteWithLayout>
          <RouteWithLayout exact path="/bernsteinschleifen">
            <Redirector />
          </RouteWithLayout>
          <RouteWithLayout exact path="/bernstein-holzschmuck">
            <Redirector />
          </RouteWithLayout>
          <RouteWithLayout exact path="/bildhauerwerkstatt">
            <Redirector />
          </RouteWithLayout>
          <RouteWithLayout exact path="/:slug">
            <ActivityDetailPage />
          </RouteWithLayout>
        </Switch>
      </EventsContextProvider>
    </ActivityContextProvider>
  );
};

export default MainRoutes;
