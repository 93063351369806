import { Slide, Grid, makeStyles, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import constants from "../../constants";
import { destination } from "../../util/get-destination";
import { colors } from "../../theme";
import CloseIcon from "@material-ui/icons/Close";
const { destinations } = constants;
const useStyles = makeStyles((theme) => ({
  teaserBar: {
    position: "absolute",
    top: 0,
    right: 0,
    maxHeight: 60,
    padding: 5,
    backgroundColor: "transparent"
  },
  teaserFilledCell: {
    backgroundColor: "white"
  },
  iconButton: {
    width: 20,
    height: 40,
    marginLeft: 4,
    backgroundColor: "none",
    color: colors.actyGray,
    "&:hover": {
      backgroundColor: "none"
    }
  }
}));
const AndroidDownloadTeaser = () => {
  const playstoreId = destinations[destination].playstoreId;
  const isAndroid = /Android/i.test(navigator.userAgent);
  const styles = useStyles();
  const [close, setClose] = useState(false);
  return isAndroid ? (
    <Slide direction="left" in={!close} timeout={300}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={styles.teaserBar}
      >
        <Grid item xs={7}></Grid>
        <Grid item xs={4} className={styles.teaserFilledCell}>
          <a
            onClick={() => {
              window.dataLayer.push({
                event: 'selectDownloadTeaser',
                os: 'android'
              })
            }}
            href={`https://play.google.com/store/apps/details?id=${playstoreId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={"/img/google-play-badge_s.png"}
              alt="Jetzt bei Google Play laden"
              style={{
                width: "100%",
                marginTop: 6
              }}
            />
          </a>
        </Grid>

        <Grid item xs={1} className={styles.teaserFilledCell}>
          <IconButton
            size="medium"
            className={styles.iconButton}
            onClick={() => setClose(true)}
            disableFocusRipple={true}
            disableRipple={true}
          >
            <CloseIcon
              style={{
                marginTop: 11
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Slide>
  ) : null;
};

export default AndroidDownloadTeaser;
