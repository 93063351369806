import React, { useState, useEffect, useContext } from "react";
import { Grid, Box, Button } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import { colors } from "../../theme";
import CategoryList from "./CategoryList";
import { ActivityContext } from "../../context/ActivitiesContext";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { isWhiteLabel } from "../../util/get-destination";
const textColor = "#14143D";

const useStyles = makeStyles((theme) => ({
  listItem: {
    height: 36,
    maxHeight: 36
  },
  checkbox: {
    color: undefined
  }
}));

const FilterBox = () => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { categories, filters } = useContext(ActivityContext);
  const [selected, setSelected] = useState(filters.categories);
  const handleToggle = (value) => {
    if (selected.indexOf(value) > -1) {
      setSelected(selected.filter((item) => item !== value));
    } else {
      setSelected([...selected, value]);
    }
  };
  const applyFilters = () => {
    const path = `${location.pathname}?categories=${selected.join(",")}`;
    history.push(path);
  };
  const resetFilters = () => {
    history.push(location.pathname);
  };
  const displayFilter = () => {
    if (selected.length !== filters.categories.length) {
      return true;
    }
    for (const cat of filters.categories) {
      if (selected.findIndex((item) => cat === item) < 0) {
        return true;
      }
    }
    return false;
  };
  const displayReset = () => {
    return selected.length > 0;
  };
  // setFilters({ params: { category_id: selected.join(",") } });
  if (isWhiteLabel) {
    return null;
  } else {
    return (
      <Box
        style={{
          margin: 0,
          padding: 12,
          position: "sticky",
          top: 80,
          // borderRadius: 12,
          borderColor: "#4196F6",
          borderLeft: "1px solid #4196F6",
          // borderStyle: 'solid',
          // borderWidth: 1,
          backgroundColor: undefined
          // backgroundColor: theme.palette.primary.main, width: '100%', height: 'auto'
        }}
      >
        <Typography
          variant="h4"
          style={{ textAlign: "left", color: textColor }}
        >
          {t("Home.Welcome")}
        </Typography>
        <CategoryList
          categories={categories}
          selected={selected}
          onToggle={handleToggle}
        />

        <div style={{ width: "100%", minWidth: "100%" }}>
          <Button
            disabled={displayFilter() === true ? false : true}
            style={{
              width: "40%",
              minWidth: "40%",
              marginLeft: 12,
              marginRight: 6
            }}
            onClick={applyFilters}
          >
            Anwenden
          </Button>
          <Button
            disabled={displayReset() === true ? false : true}
            style={{
              width: "40%",
              minWidth: "40%",
              marginLeft: 6,
              marginRight: 12
            }}
            onClick={() => {
              setSelected([]);
              resetFilters();
            }}
          >
            Zurücksetzen
          </Button>
        </div>
      </Box>
    );
  }
};

export default FilterBox;
