import React, { useState } from "react";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ListIcon from "@material-ui/icons/List";
import Button from "@material-ui/core/Button";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import LazyLoad from "react-lazyload";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import StickerBadge from "../Sticker";
import { useTranslation } from "react-i18next";

const ResponsiveLinesEllipsis = responsiveHOC()(LinesEllipsis);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxHeight: 160,
    cursor: "pointer",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 280,
    minWidth: 280,
    maxHeight: 160,
    minHeight: 160,
    height: 160,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  textWithEllipsis: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    lineHeight: 1.3,
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  hiddenAnchor: {
    textDecoration: "none",
    color: "inherit",
    "&:visited": {
      color: "inherit",
    },
  },
  cardImage: {
    height: 180,
  },
  // cardContent: {
  //   height: 40,
  //   position: "absolute",
  //   bottom: 0,
  //   backgroundColor: "#ffffff",
  //   width: "100%"
  // },
  cardTitle: {
    marginTop: -10,
    padding: 0,
    paddingTop: 0,
    lineClamp: 1,
    maxLines: 1,
  },

  cardSubTitle: {
    marginTop: -2,
    padding: 0,
    paddingTop: 0,
  },
  offerCard: {
    padding: 0,
    marginBottom: 20,
    height: 210,
  },
}));

const OfferCard = ({ id, image, title, subTitle, description, teaser, destination, isBookable, onClick, link }) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { i18n, t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const rightColSpan = isSmall ? 0 : 3;

  return (
    <LazyLoad height={160} once>
      <Card
        onMouseOver={() => {
          setIsActive(true);
        }}
        onMouseOut={() => {
          setIsActive(false);
        }}
        className={classes.root}
        elevation={isActive ? 16 : 3}
        onClick={() => onClick()}
      >
        <CardMedia
          component="div"
          alt={image.altText}
          className={classes.cover}
          image={image.url}
          title={image.altText}
        >
          {isBookable ? <StickerBadge imgurl="offerbadge.png" claim={t("Tickets.TeaserBadge")} /> : undefined}
        </CardMedia>

        {/* <div className={classes.details}> */}
        <CardContent>
          <Grid container style={{ height: "100%" }}>
            <Grid item xs={12}>
              <Typography component="h4" variant="h4" className={classes.textWithEllipsis} style={{ marginBottom: 0 }}>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  to={link}
                  className={classes.hiddenAnchor}
                >
                  {title}
                </Link>
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.textWithEllipsis}
                style={{ marginBottom: 0, color: "black" }}
              >
                {subTitle}
              </Typography>
              <Typography
                variant="subtitle1"
                color="secondary"
                style={{ marginBottom: 3 }}
                className={classes.textWithEllipsis}
              >
                {teaser}
              </Typography>
            </Grid>
            <Grid item xs={12 - rightColSpan} style={{ paddingRight: 10, height: "auto", maxHeight: 80 }}>
              <ResponsiveLinesEllipsis
                style={{ fontFamily: "Open Sans", fontSize: 14 }}
                text={description}
                maxLine={3}
                ellipsis=" ..."
                basedOn="words"
              />
            </Grid>
            {rightColSpan > 0 ? (
              <Grid item xs={3} container style={{ paddingLeft: 8, height: "auto" }}>
                <Button
                  // startIcon={<ListIcon />}
                  style={{
                    width: "100%",
                    marginTop: "auto",
                    marginLeft: "auto",
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onClick();
                  }}
                >
                  {t("ButtonActions.More")}
                </Button>
                {/* </div> */}
              </Grid>
            ) : undefined}
          </Grid>
        </CardContent>
        {/* </div> */}
      </Card>
    </LazyLoad>
  );
};

OfferCard.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    altText: PropTypes.string,
  }),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  teaser: PropTypes.string,
  link: PropTypes.string,
};

export default OfferCard;
