import React, { useState } from "react";
import {
  Grid,
  Typography,
  LinearProgress,
  makeStyles,
  List,
  ListItem,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from "@material-ui/core";

import { colors } from "../../theme";
import SlotCard from "./SlotCard";
import { createBooking } from "../../api/booking";
import { useHistory } from "react-router";
import ErrorDialog from "../ErrorDialog";
import { useTranslation } from "react-i18next";
import { timeString, getBestCancellationRule } from "../../helpers";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
// import moment from "moment/min/moment-with-locales";
import moment from "moment/min/moment-with-locales";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import RestoreOutlinedIcon from "@material-ui/icons/RestoreOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { getProviderId, isWhiteLabel } from "../../util/get-destination";
const useStyles = makeStyles((theme) => ({
  leftAlignedContainer: {
    marginLeft: "none",
    marginTop: 30,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 800
    }
  },
  subHeadline: {
    color: colors.actyLightBlue,
    fontWeight: "bold"
  },
  descriptionText: {
    lineHeight: 2,
    paddingRight: 16,
    whiteSpace: "pre-line"
  },
  activityAboutList: {
    listStyleType: "none"
  },
  ali: {
    "&::before": {
      content: "'*'",
      color: "#4196F6",
      marginLeft: 0,
      marginRight: 10
    },
    fontFamily: theme.typography.fontFamily
  },
  offerBtn: {
    backgroundColor: colors.actyRed,
    "&:hover": {
      backgroundColor: colors.actyRed
    },
    marginTop: 14,
    marginLeft: 10
  },
  variantHeadView: {
    borderColor: colors.actyLightBlue
  }
}));

const VariantDetails = ({ slots, variant }) => {
  console.log(slots);
  console.log(variant);
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { priceCategories } = variant;
  const activity = slots.length > 0 ? slots[0].activity : variant.activity;
  const [activeSlot, setActiveSlot] = useState(null);
  const [showBookOnRequestInfo, setShowBookOnRequestInfo] = useState(false);
  const [lastActiveSlot, setLastActiveSlot] = useState(null);
  const [selectedTickets, setSelectedTickets] = useState({});
  const [createBookingInProgress, setCreateBookingInProgress] = useState(false);
  const [bookingError, setBookingError] = useState(null);
  const history = useHistory();
  moment.locale(i18n.language);
  const onChangeTickets = (slot) => {
    return (index, increment) => {
      const tickets =
        selectedTickets[slot.id] || priceCategories.map((item) => 0);
      const val = tickets[index] + increment;
      if (val >= 0 && val <= slot.availableSlots) {
        setSelectedTickets({
          ...selectedTickets,
          [slot.id]: [
            ...tickets.slice(0, index),
            val,
            ...tickets.slice(index + 1, tickets.length)
          ]
        });
        console.log(selectedTickets);
      }
    };
  };

  const onChangeSlot = (id) => {
    if (activeSlot === id) {
      setActiveSlot(null);
    } else {
      if (id !== lastActiveSlot) {
        setSelectedTickets({
          ...selectedTickets,
          [lastActiveSlot]: priceCategories.map((category) => 0)
        });
      }
      window.dataLayer.push({
        event: "selectSlot",
        offerId: activity.id,
        offerTitle: activity.name,
        variantId: variant.id,
        variantName: variant.name,
        slotId: id,
        slotStartDate: slots[id]?.startDate,
        slotEndDate: slots[id]?.endDate
      });
      setActiveSlot(id);
      setLastActiveSlot(id);
    }
  };
  const onProceedToBooking = (slot) => {
    return async () => {
      try {
        setCreateBookingInProgress(true);
        const booking = await createBooking(
          slot,
          priceCategories
            .map((category, index) => {
              return {
                ...category,
                numTickets: selectedTickets[slot.id][index]
              };
            })
            .filter((e) => e.numTickets > 0)
        );
        window.dataLayer.push({
          event: "proceedToBooking",
          offerId: activity.id,
          offerTitle: activity.name,
          variantId: variant.id,
          variantName: variant.name,
          selectedSlotId: slot.id
        });
        history.push(
          `/bookings/${booking.id}${
            isWhiteLabel ? `/?pid=${getProviderId(window.location.href)}` : ""
          }`
        );
      } catch (err) {
        setBookingError(err);
      } finally {
        setCreateBookingInProgress(false);
      }
    };
  };
  return (
    <React.Fragment>
      {bookingError !== null ? (
        <ErrorDialog
          showError={true}
          errorMessage={bookingError.detail}
          errorTitle={t("BookingErrors.BookingErrorTitle")}
          closeHandler={() => setBookingError(null)}
        />
      ) : undefined}

      <div className={classes.leftAlignedContainer}>
        <Grid item container xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Card variant="outlined" className={classes.variantHeadView}>
                <CardContent>
                  <Typography variant="h2">{activity.name}</Typography>

                  <Typography className={classes.subHeadline}>
                    {variant.name}
                  </Typography>
                  <Typography variant="body2">{variant.description}</Typography>
                  <Typography variant="body2">{variant.details}</Typography>
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    alignContent="flex-start"
                  >
                    <Grid item xs={2} justifyContent="center" container>
                      <AccessTimeIcon size="medium" color="secondary" />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body2" color="primary">
                        {timeString(
                          variant.duration,
                          t("Offers.DurationSuffixHours"),
                          t("Offers.DurationSuffixMinutes")
                        )}
                      </Typography>
                    </Grid>

                    {variant.directBooking === false ? (
                      <React.Fragment>
                        <Grid item xs={2} justifyContent="center" container>
                          <InfoOutlinedIcon
                            size="medium"
                            color="secondary"
                            onClick={() => setShowBookOnRequestInfo(true)}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant="body2" color="primary">
                            {t("Offers.BookingTypeOnRequest")}
                          </Typography>
                          <Dialog
                            open={showBookOnRequestInfo}
                            onClose={() => setShowBookOnRequestInfo(false)}
                            aria-labelledby="info-dialog-title"
                            aria-describedby="info-dialog-description"
                          >
                            <DialogTitle id="info-dialog-title">
                              {t("Offers.BookingTypeOnRequest")}
                              <IconButton
                                size="small"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0
                                }}
                                onClick={() => setShowBookOnRequestInfo(false)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="info-dialog-description">
                                <Typography
                                  variant="body2"
                                  component="span"
                                  color="primary"
                                >{`${
                                  variant !== null
                                    ? variant.bookOnRequestInfo
                                    : ""
                                }`}</Typography>
                              </DialogContentText>
                            </DialogContent>
                          </Dialog>
                        </Grid>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Grid item xs={2} justifyContent="center" container>
                          <CheckCircleOutlinedIcon
                            size="medium"
                            color="secondary"
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant="body2" color="primary">
                            {t("Offers.BookingTypeDirect")}
                          </Typography>
                        </Grid>
                      </React.Fragment>
                    )}
                    <Grid item xs={2} justifyContent="center" container>
                      <RestoreOutlinedIcon size="medium" color="secondary" />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="body2" color="primary">
                        {t(
                          "Offers.CancellationInfo",
                          getBestCancellationRule(variant.cancellationTerms)
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {/* <Typography>{JSON.stringify(variant)}</Typography> */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {slots.error ? (
            <Typography>Error</Typography>
          ) : slots.loading ? (
            <LinearProgress />
          ) : slots.length > 0 ? (
            <List style={{ width: "100%" }}>
              {slots.map((slot) => {
                return (
                  <ListItem
                    key={slot.id}
                    item
                    xs={12}
                    style={{ padding: 6, width: "100%" }}
                  >
                    <SlotCard
                      slot={slot}
                      variant={variant}
                      active={activeSlot === slot.id}
                      setActive={() => {
                        onChangeSlot(slot.id);
                      }}
                      onChangeTickets={onChangeTickets(slot)}
                      onProceedToBooking={onProceedToBooking(slot)}
                      selectedTickets={
                        selectedTickets[slot.id] ||
                        priceCategories.map((category) => 0)
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <Typography
              variant="body2"
              align="center"
              color="error"
              style={{ marginTop: 20 }}
            >
              {t("Offers.VariantsBookedOut")}
            </Typography>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default VariantDetails;
