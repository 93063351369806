import React from "react";
import { makeStyles, Divider, Container, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    // width: '100vw',
    // maxWidth: '100vw',
    marginTop: 12,
    marginBottom: 24,
    [theme.breakpoints.down("xs")]: {
      paddingRight: 12,
      paddingLeft: 12,
    },
    [theme.breakpoints.up("xs")]: {
      paddingRight: undefined,
      paddingLeft: undefined,
    },
    // backgroundColor: 'blue',
    // color: 'white',
  },
  left: {
    display: "flex",
    // backgroundColor: 'green',
    marginLeft: 0,
    padding: 0,
  },
  right: {
    display: "flex",
    // textAlign: 'right',
    margin: 0,
    marginLeft: "auto",
    padding: 0,

    // backgroundColor: 'red'
  },
  footerText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 8,
    },
    [theme.breakpoints.up("xs")]: {
      fontSize: 12,
    },
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
  },
}));

const Footer = () => {
  const { i18n, t } = useTranslation();
  const classes = useStyles();
  return (
    <React.Fragment>
      <Divider maxWidth="lg" />

      <Container maxWidth="lg" align="center" className={classes.root}>
        <div className={classes.left}>
          <span className={classes.footerText}>{"© 2021 actyvyst GmbH"}</span>
        </div>
        <div className={classes.right}>
          <a
            className={classes.footerText}
            href={`https://actyvyst.de/impressum/?language=${i18n.language}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("LinkActions.Imprint")}
          </a>
          &#x00A0;
          <a
            className={classes.footerText}
            href={`https://actyvyst.de/datenschutz/?language=${i18n.language}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("LinkActions.DataSecurity")}
          </a>
          &#x00A0;
          <a className={classes.footerText} href="mailto:contact@actyvyst.com">
            {t("ButtonActions.Contact")}
          </a>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Footer;
