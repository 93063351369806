import axios from 'axios';
import config from "../config";
const { apiPath } = config;

const sendProviderRequest = async (activityId, request) => {
  try {
    await axios.post(`${apiPath}/activities/${activityId}/sendrequest`, request);
  } catch (err) {
    console.log(err);
    throw err
  }
}

export default sendProviderRequest;
