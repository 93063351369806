import { useContext, useState, useEffect } from "react";
import ActivityList from "../components/ActivityList";
import { ActivityContext } from "../context/ActivitiesContext";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { useHistory } from "react-router-dom";
import { isWhiteLabel } from "../util/get-destination";

import { useCategories, useActivities } from "../api/activities";

// import {  } from "../util/get-destination";
// import { useLocation } from "react-router";
import LoadingIndicator from "../components/LoadingIndicator";
import MainNavLink from "../components/MainNavLink";
import { useTranslation } from "react-i18next";

export default function ActivitiesPage() {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  // const [filters, setFilters] = useState({
  //   categories: []
  // });

  const { activities, categories, loading, error, filters, setFilters } = useContext(ActivityContext);

  // const { activities, activitiesLoading } = useActivities({ ...filters });
  // const { categories, categoriesLoading } = useCategories({});

  // useEffect(() => {
  //   console.log('Activities mounted')
  //   const query = qs.parse(location.search)
  //   const f = query.categories ? query.categories.split(',') : [];
  //   setFilters({ categories: f });
  //   return () => { console.log('Activites unmounted') }
  // }, [location.pathname]);

  useEffect(() => {
    const query = qs.parse(location.search);
    const f = query.categories ? query.categories.split(",") : [];
    setFilters({ categories: f });
  }, [location.search]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          minWidth: "100%",
          height: "100%",
          minHeight: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  const navigateToEvents = () => {
    history.push("/events/upcoming");
  };

  return (
    <div>
      {!isWhiteLabel ? (
        <MainNavLink
          to="/events/upcoming"
          text={t("NavigationTitles.Events")}
          position={"right"}
          onClick={navigateToEvents}
        />
      ) : undefined}

      <ActivityList activities={activities} categories={categories} filters={filters} />
    </div>
  );
}
