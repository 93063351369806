import React from "react";
import {
  Container,
  Grid,
  Typography,
  useTheme,
  makeStyles,
  useMediaQuery
} from "@material-ui/core";
import HeaderFilterBox from "../FilterBox/HeaderFilterBox";
import { destination } from "../../util/get-destination";
import constants from "../../constants";
import { useTranslation } from "react-i18next";
const { destinations } = constants;

const useStyles = makeStyles((theme) => ({
  headerText: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8em"
    }
  }
}));

const ActivityListHeader = ({ categories, showFilter }) => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { t } = useTranslation();
  const leftColSize = isMobile ? 0 : 8;
  return (
    // <Container style={{ marginTop: 24, }}>
    <Grid container style={{ marginTop: 24 }}>
      {isMobile ? undefined : (
        <Grid item xs={leftColSize}>
          <Typography variant="h4">
            {t("General.ActivityListLabel", {
              dest: destinations[destination]?.name
            })}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12 - leftColSize}>
        {showFilter ? <HeaderFilterBox categories={categories} /> : undefined}
      </Grid>
    </Grid>
    // </Container>
  );
};

export default ActivityListHeader;
