const config = {
  // apiPath: 'http://localhost:3000',
  // apiPath: 'https://stage.api.actyvyst.de',
  defaultDestination: process.env.REACT_APP_DEFAULT_DESTINATION || "sylt",
  apiPath: process.env.REACT_APP_API_HOST,
  isWhiteLabel: process.env.REACT_APP_IS_WL === "true" ? true : false,
  imagePath: "https://media.actyvyst.de/images/activities/raw",
  wlLogoPath: "https://media.actyvyst.de/images/provider",
  adMediaPath: "https://media.actyvyst.de/images",
  localIp: !process.env.REACT_APP_PAYPAL_RETURN_HOST
    ? `${window.location.protocol}//${window.location.hostname}`
    : process.env.REACT_APP_PAYPAL_RETURN_HOST,
  //"http://gowebstack-reactappbuckete340e270-18bqositiby0k.s3-website.eu-central-1.amazonaws.com",
  weekdays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  monthTLCs: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
};

export default config;
