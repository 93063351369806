import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  makeStyles,
  Grid,
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { numberFormat } from "../../helpers";
import DownloadDialog from "../DownloadDialog/index.js";
import { destination } from "../../util/get-destination";
import { colors } from "../../theme";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  gridContainer: { padding: 0, margin: 0 },
  offerListItem: {
    color: colors.actyDarkBlue,
    fontFamily: theme.typography.fontFamily,

    fontSize: "0.9rem",
  },
  subListItem: {
    marginRight: 10,
    color: colors.actyDarkBlue,
  },
  btnIcon: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  offerBtn: {
    color: "white",
    backgroundColor: colors.actyLightBlue,
    "&:hover": {
      backgroundColor: colors.actyLightBlue,
    },
    width: "100%",
    height: 40,
    margin: 0,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 6,
    },
  },
}));

const ContactInfo = ({ onOpenContactInfo }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const classes = useStyles();

  return (
    <React.Fragment>
      <List>
        <ListItem>
          <Grid container className={classes.gridContainer}>
            <Grid container item xs={7} className={classes.gridContainer}>
              <ListItemText
                primary={t("ProviderContact.Teaser")}
                // secondary={"Fragen?"}
                classes={{
                  primary: classes.offerListItem,
                  secondary: classes.subListItem,
                }}
              />
            </Grid>
            <Grid item xs={5} className={classes.gridContainer}>
              {/* <ListItemIcon style={{ padding: 0, margin: 0 }}> */}
              <Button
                secondary
                onClick={() => {
                  onOpenContactInfo();
                }}
                className={classes.offerBtn}
              >
                {t("ProviderContact.Button")}
                <ChevronRightIcon className={classes.btnIcon} />
              </Button>
              {/* </ListItemIcon> */}
            </Grid>
          </Grid>
        </ListItem>
        {/* <ListItem>
          <Grid container className={classes.gridContainer}>
            <Grid container item xs={8} className={classes.gridContainer}>
              <ListItemText
                primary={"actyvyst Hotline"}
                // secondary={"Fragen?"}
                classes={{
                  primary: classes.offerListItem,
                  secondary: classes.subListItem,
                }}
              />
            </Grid>
            <Grid container item xs={4} className={classes.gridContainer}>
              <Button
                secondary
                onClick={() => {
                  onOpenContactInfo();
                }}
                className={classes.offerBtn}
              >
                Kontakt
                <ChevronRightIcon />
              </Button>
            </Grid>
          </Grid>
        </ListItem> */}
      </List>
    </React.Fragment>
  );
};

export default ContactInfo;
