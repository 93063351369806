import React, { useState, useEffect } from "react";
import {} from "@material-ui/core";

import { confirmPayment } from "../../api/booking";
import LoadingIndicator from "../LoadingIndicator";
import BookingSummary from "../BookingSummary";
import BookingForm from "../BookingForm";

const BookingStatus = ({
  booking,
  token,
  payerId,
  paymentCanceled = false
}) => {
  const [completedBooking, setCompletedBooking] = useState(null);
  const [loading, setLoading] = useState(true);
  const [actyvystError, setActyvystError] = useState(null);

  useEffect(() => {
    async function completeBooking() {
      try {
        setLoading(true);
        const response = await confirmPayment(booking.id);
        setCompletedBooking(response);
      } catch (err) {
        setActyvystError(err);
      } finally {
        setLoading(false);
      }
    }
    if (!paymentCanceled) {
      completeBooking();
    } else {
      setLoading(false);
    }
  }, []);

  return loading && completedBooking === null && !paymentCanceled ? (
    <div
      style={{
        display: "flex",
        width: "100%",
        minWidth: "100%",
        height: "100%",
        minHeight: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white"
      }}
    >
      <LoadingIndicator />
    </div>
  ) : paymentCanceled ? (
    <BookingForm booking={booking} />
  ) : actyvystError !== null ? (
    <BookingForm booking={booking} bookingError={actyvystError} />
  ) : (
    <BookingSummary
      booking={completedBooking}
      token={token}
      payerId={payerId}
    />
  );
};

export default BookingStatus;
