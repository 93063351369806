import React from "react";
import { Grid, Typography, IconButton, makeStyles } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { colors } from "../../theme";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  captionText: {
    fontSize: "0.7em",
    paddingRight: 6,
    color: colors.actyGray,
  },
  catText: {
    marginLeft: 4,
    fontSize: "0.9em",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  catTextSubline: {
    marginLeft: 4,
    fontSize: "0.7em",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  ticketIndex: {
    fontSize: "1.3em",
    fontWeight: "bold",

    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginLeft: 18,
      marginRight: 0,
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "center",
      marginLeft: 0,
      marginRight: 10,
    },
  },

  priceText: {
    paddingRight: 6,

    marginLeft: 4,
    fontSize: "0.9em",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: "bold",
    color: colors.actyRed,
  },
  dividerLine: {
    marginTop: 6,
    paddingTop: 3,
    borderTopWidth: 1,
    borderTopStyle: "solid",

    borderTopColor: "#EEEEEE",
  },
  //   leftIconButton: {
  //     [theme.breakpoints.up("sm")]: {
  //       marginLeft: 24
  //     }
  //   }
}));
const ParticipantsPanel = ({
  variant,
  slot,
  selectedTickets,
  onChangeTickets,
  isMobile,
}) => {
  const { priceCategories } = variant;
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  const displayPrice = (price) => {
    return `${(price / 100).toLocaleString(i18n.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} €`;
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        justifyContent="flex-start"
      >
        <Grid item xs={1}></Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5}>
          <Typography
            variant="body2"
            align="left"
            className={styles.captionText}
          >
            {t("Offers.TicketType")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="body2"
            align="right"
            className={styles.captionText}
          >
            {t("Offers.TicketPrice")}
          </Typography>
        </Grid>
      </Grid>

      {priceCategories.map((category, index) => {
        return (
          <Grid
            key={`cat-${index}`}
            container
            direction="row"
            alignContent="flex-start"
            justifyContent="flex-start"
          >
            <Grid
              item
              container
              xs={1}
              justifyContent={isMobile ? "flex-start" : "flex-end"}
              alignItems="flex-end"
              alignContent="flex-start"
            >
              <IconButton
                disabled={selectedTickets[index] <= 0}
                disableRipple={true}
                align="right"
                onClick={() => {
                  onChangeTickets(index, -1);
                }}
                style={{ backgroundColor: "transparent" }}
              >
                <RemoveCircleOutlineIcon
                  fontSize={isMobile ? "medium" : "large"}
                />
              </IconButton>
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                color="secondary"
                align="right"
                className={styles.ticketIndex}
              >
                {selectedTickets[index]}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                disableRipple={true}
                disabled={
                  selectedTickets.reduce((acc, cur, index) => {
                    return (
                      acc + cur * priceCategories[index].allowedParticipants
                    );
                  }, priceCategories[index].allowedParticipants) >
                  slot.availableSlots
                }
                edge="start"
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  onChangeTickets(index, 1);
                }}
              >
                <AddCircleOutlineIcon
                  fontSize={isMobile ? "medium" : "large"}
                />
              </IconButton>
            </Grid>

            <Grid item xs={5}>
              <Typography gutterBottom={false} className={styles.catText}>
                {category.name}
              </Typography>
              <Typography
                variant="caption"
                gutterBottom={false}
                className={styles.catTextSubline}
              >
                {category.description}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant="body2"
                className={styles.priceText}
                align="right"
              >
                {displayPrice(priceCategories[index].price)}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
      <Grid
        container
        direction="row"
        alignContent="flex-start"
        justifyContent="flex-start"
        className={styles.dividerLine}
      >
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={6}>
          <Typography
            variant="body2"
            color="secondary"
            className={styles.priceText}
            align="left"
          >
            {t("Offers.TotalLabel")}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle2"
            align="right"
            className={styles.priceText}
          >
            {displayPrice(
              priceCategories.reduce((acc, cur, index) => {
                return acc + cur.price * selectedTickets[index];
              }, 0)
            )}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default ParticipantsPanel;
