import { useState } from "react";
import { Typography, Button, Card, Grid, CardContent, CardMedia } from "@material-ui/core";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import { useHistory } from "react-router-dom";

import { colors } from "../../theme";

const useStyles = makeStyles(() => ({
  textWithEllipsis: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "wrap",
    lineHeight: 1.3,
  },
}));

export const AdCardNormal = (props) => {
  const history = useHistory();

  const { image, title, teaser, buttonTitle, link, isActive: activeCard } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [isActive, setIsActive] = useState(false);
  const rightColSpan = isSmall ? 0 : 3;

  return (
    <LazyLoad height={160} once>
      <Card
        onMouseOver={() => {
          setIsActive(true);
        }}
        onMouseOut={() => {
          setIsActive(false);
        }}
        elevation={isActive ? 16 : 3}
        onClick={() => {
          history.push(link);
        }}
        style={{
          display: "flex",
          minWidth: "100%",
          maxHeight: 160,
          cursor: "pointer",
          borderWidth: 1,
          borderColor: activeCard ? colors.actyRed : colors.actyDarkBlue,
          borderStyle: "solid",
        }}
      >
        {/* {activeCard ? undefined : ( */}
        <CardMedia
          component="img"
          alt={image.altText}
          image={image.url}
          title={image.altText}
          style={{ width: activeCard ? 260 : "60%", padding: 10, objectFit: "contain" }}
        ></CardMedia>
        {/* )} */}

        <CardContent style={{ width: "100%" }}>
          <Grid container style={{ flexDirection: "column", justifyContent: "end", alignItems: "flex-start" }}>
            <Typography component="h4" variant="h4" className={classes.textWithEllipsis} style={{ marginBottom: 0 }}>
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              color="secondary"
              style={{ marginBottom: 3 }}
              className={classes.textWithEllipsis}
            >
              {teaser}
            </Typography>
            {rightColSpan > 0 ? (
              <Button
                style={{ width: "100%", marginTop: 10, marginBottom: 2, marginLeft: "auto" }}
                onClick={(event) => {
                  event.stopPropagation();
                  history.push(link);
                }}
              >
                {buttonTitle}
              </Button>
            ) : undefined}
          </Grid>
        </CardContent>
      </Card>
    </LazyLoad>
  );
};

AdCardNormal.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    altText: PropTypes.string,
  }),
  title: PropTypes.string,
  teaser: PropTypes.string,
  link: PropTypes.string,
};
