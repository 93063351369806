
import { useData } from "./useData";

function useVariants(activity) {
  const { data, error, loading } = useData(`/activities/${activity}/variants`);

  return {
    variants: data,
    variantsLoading: loading,
    variantsError: error
  };
}

function useVariant(id) {
  const { data, loading, error, retry } = useData(`/activities/variants/${id}`);
  return {
    variant: data,
    variantLoading: loading,
    variantError: error,
    retry
  };
}

export { useVariants, useVariant };
