import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core";
import { colors } from '../../theme';

const useStyles = makeStyles((theme) => ({
  listItem: {
    height: 36,
    maxHeight: 36
  },
  listItemText: {
    color: colors.actyLightBlue,
    [theme.breakpoints.down("xs")]: {
      fontSize: '0.82rem'
    },
  },
  checkbox: {
    color: theme.palette.secondary.main
  }
}));

export default function CategoryList({ categories, selected, onToggle }) {
  const classes = useStyles();
  return (
    <List>
      {categories.map((category) => {
        const labelId = `category-${category.id}`;
        return (
          <ListItem
            className={classes.listItem}
            key={category.id}
            button
            onClick={() => onToggle(category.id)}
          >
            <ListItemIcon>
              <Checkbox
                className={classes.checkbox}
                edge="start"
                checked={selected.indexOf(category.id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": labelId }}
              />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                className: classes.listItemText
              }}
              id={labelId}
              primary={category.name}
              className={classes.listItemText}
            // style={{ color: colors.actyLightBlue }}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
