import React, { useState, useContext } from "react";
import {
  Grid,
  Typography,
  LinearProgress,
  Divider,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Button,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import Config from "../../config";
import ImageGallery from "../ImageGallery";
import MapView from "../MapView";

import HeaderOfferBox from "../OfferList/HeaderOfferBox";
import OfferList from "../OfferList/";
import ProviderContactForm from "../ProviderContactForm";
import { useVariants } from "../../api/variants";
import { useExternalIBEs } from "../../api/activities";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { getProviderId, isWhiteLabel } from "../../util/get-destination";
import { colors } from "../../theme";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ContactInfo from "../ContactInfo";
import MainNavLink from "../MainNavLink";
import { AppContext } from "../../context/AppContext";

const { imagePath } = Config;

const useStyles = makeStyles((theme) => ({
  detailHeadline: {
    marginTop: 10,
  },
  priceHeadline: {
    color: colors.actyRed,
    paddingLeft: 15,
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
    fontSize: "1rem",
  },
  contactHeadline: {
    color: colors.actyLightBlue,
    paddingLeft: 15,
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
    fontSize: "1rem",
  },
  descriptionText: {
    lineHeight: 2,
    paddingRight: 16,
    whiteSpace: "pre-line",
  },
  activityAboutList: {
    listStyleType: "none",
  },
  ali: {
    "&::before": {
      content: "'*'",
      color: "#4196F6",
      marginLeft: 0,
      marginRight: 10,
    },
    fontFamily: theme.typography.fontFamily,
  },
  offerBtn: {
    backgroundColor: colors.actyRed,
    "&:hover": {
      backgroundColor: colors.actyRed,
    },
    marginTop: 14,
    marginLeft: 10,
  },
  dividerLine: {
    backgroundColor: colors.actyDarkBlue,
  },
  dialog: {
    width: "100%",
    maxWidth: 400,
    padding: 0,
    margin: "auto",
  },
  dialogContent: {
    // padding: 8,
  },
  dialogTitle: {
    padding: "16px 16px 8px 16px",
  },
}));

export default function ActivityDetails({ activity }) {
  const { isInitialPage } = useContext(AppContext);
  const history = useHistory();
  const { variants, variantsLoading, variantsError } = useVariants(activity.id);
  const { t } = useTranslation();
  const ibeConfigs = useExternalIBEs(activity.id);
  console.log({ activity });
  const classes = useStyles();
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [contactDialogState, setContactDialogState] = useState("form");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isMedium = useMediaQuery(theme.breakpoints.down("sm"));
  const rightColwidth = isMobile || isMedium ? 3 : 0;
  const variantsContent =
    variants !== null
      ? variants
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
              description: item.description,
              displayOnly: false,
              displayPrice:
                item.priceCategories.reduce((acc, cur) => {
                  return acc <= 0 ? cur.price : cur.price < acc ? cur.price : acc;
                }, 0) / 100,
            };
          })
          .concat(
            ibeConfigs.data.map((item) => {
              return {
                id: item.id,
                type: "ibe",
                name: item.name,
                displayOnly: item.displayOnly || false,
                description: item.description,
                displayPrice: item.teaserPrice / 100,
                deeplink: item.deeplink,
              };
            })
          )
          .sort((a, b) => {
            return a.displayPrice < b.displayPrice ? -1 : 1;
          })
      : [];

  const selectVariant = (item) => {
    if (item.type === "ibe") {
      window.dataLayer.push(
        {
          event: "openExternalIBE",
          offerId: activity.id,
          offerTitle: activity.name,
          externalIbeId: item.id,
          externalIbeName: item.name,
        },
        {
          event: "startExternalIBE",
          offerId: activity.id,
          offerTitle: activity.name,
          externalIbeId: item.id,
          externalIbeName: item.name,
        }
      );
      window.open(item.deeplink);
    } else {
      history.push(`/variants/${item.id}${isWhiteLabel ? `/?pid=${getProviderId(window.location.href)}` : ""}`);
    }
  };

  return (
    <>
      <MainNavLink text={t("NavigationTitles.BackToActivityList")} />
      <Grid container>
        <Grid item xs={12 - rightColwidth} md={12 - rightColwidth} lg={12 - rightColwidth}>
          <Typography variant="h3" className={classes.detailHeadline}>
            {activity.name}
          </Typography>
        </Grid>

        {rightColwidth > 0 ? (
          <Grid item container xs={rightColwidth}>
            <div style={{ backgroundColor: undefined, width: "100%" }}>
              <HeaderOfferBox
                content={variantsContent}
                isMedium={isMedium}
                isMobile={isMobile}
                onSelectItem={selectVariant}
                onOpenContactInfo={() => {
                  setContactDialogState("form");
                  setContactDialogOpen(true);
                }}
              />
            </div>
          </Grid>
        ) : undefined}
        <Grid item xs={12}>
          <Typography variant="subtitle2">{activity.teaser}</Typography>
        </Grid>

        <Grid item md={8} xs={12} lg={8}>
          <ImageGallery
            images={
              activity.images
                ? activity.images.map((img) => {
                    return {
                      description: img.description,
                      url: `${imagePath}/${img.url}`,
                    };
                  })
                : []
            }
            isMedium={isMedium}
            isMobile={isMobile}
          />
        </Grid>
        {rightColwidth > 0 ? undefined : (
          <Grid item md={4} lg={4}>
            {variantsError ? (
              <Typography>{variantsError}</Typography>
            ) : variantsLoading ? (
              <LinearProgress />
            ) : variantsContent.length > 0 ? (
              <React.Fragment>
                <Typography variant="h5" className={classes.priceHeadline}>
                  {t("ButtonActions.Prices")}
                </Typography>
                <Divider variant="middle" className={classes.dividerLine} />
                <OfferList
                  content={variantsContent}
                  isMedium={isMedium}
                  isMobile={isMobile}
                  onSelectItem={selectVariant}
                />
              </React.Fragment>
            ) : undefined}
            <Typography variant="h5" className={classes.contactHeadline}>
              {t("ProviderContact.Headline")}
            </Typography>
            <Divider variant="middle" className={classes.dividerLine} />
            <ContactInfo
              onOpenContactInfo={() => {
                setContactDialogState("form");
                setContactDialogOpen(true);
              }}
            />
          </Grid>
        )}

        <Dialog
          classes={{
            root: classes.dialog,
            container: classes.dialog,
            paper: classes.dialog,
          }}
          open={contactDialogOpen}
          onClose={() => {
            setContactDialogOpen(false);
          }}
        >
          <div
            style={{
              position: "absolute",
              right: 0,
              top: 2,
              marginRight: 4,
              marginTop: 2,
            }}
          >
            <IconButton
              onClick={() => {
                setContactDialogOpen(false);
                // setContactDialogState("form");
              }}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <DialogTitle classes={{ root: classes.dialogTitle }} disableTypography>
            <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>{t("ProviderContact.Dialog.Headline")}</span>
          </DialogTitle>
          <DialogContent classes={{ root: classes.dialogContent }}>
            {contactDialogState === "form" ? (
              <ProviderContactForm
                onSuccess={() => {
                  setContactDialogState("success");
                }}
                onFailure={() => {
                  setContactDialogState("failure");
                }}
                activityId={activity.id}
              />
            ) : contactDialogState === "success" ? (
              <Box>
                <Typography>{t("ProviderContact.Dialog.Success")}</Typography>
                <Button style={{ width: "100%", margin: "16px 0 16px 0" }} onClick={() => setContactDialogOpen(false)}>
                  Ok
                </Button>
              </Box>
            ) : (
              <div style={{ width: "100%", maxWidth: "40ch" }}>
                <Box>
                  <Typography>{t("ProviderContact.Dialog.Failure")}</Typography>
                  <Button
                    style={{ width: "100%", margin: "16px 0 16px 0" }}
                    onClick={() => setContactDialogOpen(false)}
                  >
                    Ok
                  </Button>
                </Box>
              </div>
            )}
          </DialogContent>
        </Dialog>
        <Grid item md={8} xs={12} lg={8} style={{ paddingRight: 10 }}>
          <Typography variant="h5" className={classes.descriptionText}>
            {t("General.Description")}
          </Typography>
          <Typography variant="body2" className={classes.descriptionText}>
            {activity.description}
          </Typography>
          <Divider variant="middle" style={{ marginTop: 16, marginBottom: 16 }} />
          <Typography variant="h5">{t("DetailView.AboutThis")}</Typography>

          <ul className={classes.activityAboutList}>
            {activity.infos.map((info, index) => {
              return (
                <li key={`info_${index}`} className={classes.ali}>
                  <span className={classes.descriptionText}>{info}</span>
                </li>
              );
            })}
          </ul>

          {variantsContent.length > 0 ? (
            <HeaderOfferBox
              content={variantsContent}
              isMedium={isMedium}
              isMobile={isMobile}
              onSelectItem={selectVariant}
              onOpenContactInfo={() => {
                setContactDialogState("form");
                setContactDialogOpen(true);
              }}
            />
          ) : undefined}

          <Divider variant="middle" style={{ marginTop: 16, marginBottom: 16 }} />

          <Typography variant="h5">{t("General.Place")}</Typography>
          <Typography variant="body2">
            {activity.location.street}
            <br></br>
            {`${activity.location.zip} ${activity.location.city}`}
          </Typography>

          <MapView coords={{ lat: activity.location.lat, lng: activity.location.lng }} />
        </Grid>
      </Grid>
    </>
  );
}
