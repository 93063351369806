import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import BookingSummary from "../BookingSummary";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Switch, Link } from "@material-ui/core";
import { useHistory } from "react-router";
import StripePayment from "./stripe";
import PaypalPayment from "./paypal";
import { Constants } from "@actyvyst/common";
import axios from "axios";
import config from "../../config";
import ScrollDialog from "../ScrollDialog";
import { colors } from "../../theme";
import { useGtc } from "../../api/booking";
import { useTranslation } from "react-i18next";
import ErrorDialog from "../ErrorDialog";
import { validate } from "../../helpers";
import { BookingStates } from "@actyvyst/common/src/constants/booking";
import { getProviderId, isWhiteLabel } from "../../util/get-destination";
const { apiPath, localIp } = config;
const { PaymentProviders } = Constants;

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "90%",
    fontSize: 12,
    "& input::placeholder": {
      fontSize: "0.8em",
    },
  },
  descriptionText: {
    padding: 0,
    lineHeight: 1,
  },
  radioBtn: {
    color: colors.actyLightBlue,
    "&$checked": {
      color: colors.actyLightBlue,
    },
  },
}));

const PaymentSelector = ({ paymentMethod, setPaymentMethod }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container direction="row" alignContent="flex-start" alignItems="flex-start">
      <Grid item xs={4}>
        <Typography variant="subtitle1" component="span">
          {t("ButtonActions.DisabledSelectPayment")}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <FormControl component="fieldset">
          {/* <FormLabel component="legend">Gender</FormLabel> */}
          <RadioGroup
            aria-label="payment-method"
            name="payment-method"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <FormControlLabel
              value={PaymentProviders.PAYPAL}
              control={<Radio className={classes.radioBtn} />}
              label={t("Payment.PayPal")}
            />
            <FormControlLabel
              value={PaymentProviders.STRIPE}
              control={<Radio className={classes.radioBtn} />}
              label={t("Payment.CC")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const BookerForm = ({ booker, onBookerChange, tocConfirmed, tocHandler, validationHandler, validEmail, validName }) => {
  const classes = useStyles();
  const [showToc, setShowToc] = useState(false);
  const gtcContent = useGtc();
  const { t } = useTranslation();

  const showTocHandler = () => {
    setShowToc(!showToc);
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <ScrollDialog open={showToc} handleClose={showTocHandler} content={gtcContent} />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle1">{t("Booking.PersonalData")}</Typography>
      </Grid>
      <Grid item xs={8}>
        <TextField
          margin="dense"
          variant="outlined"
          value={booker.name}
          label={t("Booking.BookerNameLabel")}
          placeholder={t("Booking.BookerNamePlaceholder")}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
            style: {
              color: validName === false ? colors.actyError : colors.actyLightBlue,
            },
          }}
          onChange={(e) => onBookerChange("name", e.target.value)}
          onBlur={(e) => validationHandler(e.target.value, "name")}
          helperText={validName === false ? t("ValidationMessages.Name") : ""}
          error={validName === false ? true : false}
        />
      </Grid>
      <Grid item xs={4}>
        {/* <Typography variant="subtitle1">E-Mail</Typography> */}
      </Grid>
      <Grid item xs={8}>
        <TextField
          margin="dense"
          InputLabelProps={{
            shrink: true,
            style: {
              color: validEmail === false ? colors.actyError : colors.actyLightBlue,
            },
          }}
          variant="outlined"
          value={booker.email}
          label={t("Booking.BookerMailLabel")}
          placeholder={t("Booking.BookerMailPlaceholder")}
          className={classes.textField}
          onChange={(e) => onBookerChange("email", e.target.value)}
          onBlur={(e) => validationHandler(e.target.value, "email")}
          helperText={validEmail === false ? t("ValidationMessages.Email") : ""}
          error={validEmail === false ? true : false}
        />
      </Grid>
      <Grid item xs={4}>
        {/* <Typography variant="subtitle1">Telefon</Typography> */}
      </Grid>
      <Grid item xs={8}>
        <TextField
          margin="dense"
          variant="outlined"
          value={booker.phone}
          label={t("Booking.BookerPhoneLabel")}
          placeholder={t("Booking.BookerPhonePlaceholder")}
          InputLabelProps={{
            shrink: true,
            style: { color: colors.actyLightBlue },
          }}
          className={classes.textField}
          onChange={(e) => onBookerChange("phone", e.target.value)}
        />
      </Grid>
      <Grid container item xs={4} direction="column" alignItems="flex-end">
        <Switch checked={tocConfirmed} onChange={tocHandler}></Switch>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2" component="span">
          {t("General.Iread")}
          <Link color="secondary" onClick={() => setShowToc(true)}>
            {t("General.TCFromUs")}
          </Link>{" "}
          {t("General.Iconfirm")}
        </Typography>
      </Grid>
    </Grid>
  );
};

const BookingForm = ({ booking, bookingErr = null }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [booker, setBooker] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [showBookingError, setShowBookingError] = useState(false);
  const [bookingError, setBookingError] = useState(null);

  const [validName, setValidName] = useState(null);
  const [validEmail, setValidEmail] = useState(null);
  useEffect(() => {
    if (bookingErr !== null) {
      errorCallback(bookingErr);
    }
    return () => {
      setBookingError(null);
      setShowBookingError(false);
    };
  }, [bookingErr]);
  const validateBooker = (value, type) => {
    switch (type) {
      case "name":
        setValidName(validate(value.trim(), type));
        break;
      case "email":
        setValidEmail(validate(value.trim(), type));
        break;

      default:
        break;
    }
  };
  const [clientSecret, setClientSecret] = useState(null);
  const [approveUrl, setApproveUrl] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(PaymentProviders.PAYPAL);
  const [loading, setLoading] = useState(false);
  const [tocConfirmed, setTocConfirmed] = useState(false);
  const history = useHistory();
  const tocHandler = () => {
    setTocConfirmed(!tocConfirmed);
  };
  const whiteLabelApproveUrl = (aurl) => {
    const tempurl = new URL(aurl);
    if (isWhiteLabel) {
      tempurl.searchParams.append("pid", getProviderId(window.location.href));
    }
    return tempurl;
  };
  const onBookerChange = (field, value) => {
    setBooker({
      ...booker,
      [field]: value,
    });
  };

  const proceedToPayment = () => {
    const proceed = async () => {
      try {
        setLoading(true);
        await axios.patch(`${apiPath}/activities/bookings/${booking.id}`, {
          booker: {
            name: booker.name,
            email: booker.email,
            phone: booker.phone,
          },
        });
        const response = await axios.post(`${apiPath}/activities/bookings/${booking.id}/createintent`, {
          paymentProvider: paymentMethod,
          returnUrl: whiteLabelApproveUrl(`${localIp}/booking/paypalreturn/${booking.id}`),
          cancelUrl: whiteLabelApproveUrl(`${localIp}/booking/paypalcancel/${booking.id}`),
        });
        console.log({ response });

        switch (paymentMethod) {
          case PaymentProviders.PAYPAL:
            if (response.data?.providerDetails?.approveUrl) {
              setApproveUrl(response.data.providerDetails.approveUrl);
              setStep(1);
            } else {
              throw new Error("invalid approveUrl");
            }
            break;
          case PaymentProviders.STRIPE:
            if (response.data?.providerDetails?.clientSecret) {
              setClientSecret(response.data.providerDetails.clientSecret);
              setStep(1);
            } else {
              throw new Error("invalid clientsecret");
            }
            break;
          default:
        }
      } catch (err) {
        errorCallback(err);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    proceed();
  };

  const redirectFromPayment = () => {
    history.push(
      `/booking/ccconfirmed/${booking.id}${isWhiteLabel ? `/?pid=${getProviderId(window.location.href)}` : ""}`
    );
  };
  const errorCallback = (err) => {
    console.log("BOOKINGERROR:", err);
    setBookingError(err);
    setShowBookingError(true);
  };
  return (
    <React.Fragment>
      <BookingSummary booking={booking} />
      {step === 0 && booking.status === BookingStates.CREATED ? (
        <React.Fragment>
          <BookerForm
            booker={booker}
            onBookerChange={onBookerChange}
            tocHandler={tocHandler}
            validationHandler={validateBooker}
            validEmail={validEmail}
            validName={validName}
          />
          <PaymentSelector paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
          <Grid container direction="row" justifyContent="flex-end" alignContent="flex-end" item>
            <Button
              disabled={loading || tocConfirmed === false || !validEmail || !validName}
              onClick={() => {
                proceedToPayment();
              }}
            >
              {loading ? <CircularProgress size={20} color="secondary" /> : t("ButtonActions.SelectPayment")}
            </Button>
          </Grid>
        </React.Fragment>
      ) : undefined}
      {step === 1 && booking.status === BookingStates.CREATED ? (
        paymentMethod === PaymentProviders.PAYPAL ? (
          approveUrl !== null ? (
            <PaypalPayment approveUrl={approveUrl} />
          ) : undefined
        ) : clientSecret !== null ? (
          <StripePayment
            onComplete={redirectFromPayment}
            clientSecret={clientSecret}
            holderName={booker.name}
            errorCreateHandler={errorCallback}
          />
        ) : undefined
      ) : undefined}
      {bookingError !== null && showBookingError ? (
        <ErrorDialog
          errorMessage={bookingError.message}
          showError={showBookingError}
          closeHandler={() => setShowBookingError(false)}
          errorTitle={bookingError.title}
        />
      ) : undefined}
    </React.Fragment>
  );
};

export default BookingForm;
