import React from "react";
import { useParams, useLocation } from "react-router";
import ActivityDetails from "../components/ActivityDetails";
import { useActivity } from "../api/activities";
import LoadingIndicator from "../components/LoadingIndicator";
import qs from "query-string";
import { Redirect } from "react-router-dom";

export default function ActivityDetailPage() {
  const location = useLocation();
  const { slug } = useParams();
  const query = qs.parse(location.search);
  // console.log({
  //   location: location.pathname,
  //   query: location.query,
  //   a_id: query.a_id,
  // });
  const { activity, activityLoading, activityError } = useActivity(query.a_id);
  if (activityLoading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          minWidth: "100%",
          height: "100%",
          minHeight: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }
  if (activityError) {
    return <Redirect to="/" />;
  }
  return <ActivityDetails activity={activity} />;
}
