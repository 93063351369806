import React from "react";
import { useParams } from "react-router";
import BookingForm from "../../components/BookingForm";
import LoadingIndicator from "../../components/LoadingIndicator";
import { useBooking } from "../../api/booking";
export default function BookingFormPage() {
  const { id } = useParams();

  console.log(id);
  const { booking, bookingLoading, bookingError } = useBooking(id);
  if (bookingLoading) {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          minWidth: "100%",
          height: "100%",
          minHeight: "100%",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <LoadingIndicator />
      </div>
    );
  }

  return <BookingForm booking={booking} bookingErr={bookingError} />;
}
