import { useState } from "react";
import {
  TextField,
  FormControl,
  FormControlLabel,
  Button,
  Switch,
  makeStyles,
} from "@material-ui/core";
import { useFormik } from "formik";
import validator from "validator";
import sendProviderRequest from "../../api/provider-contact";
import { useTranslation } from "react-i18next";

const phoneNumberRegex = /^[0-9\-/ ()+]*$/;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "100%",
      // maxWidth: "35ch",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  label: {
    color: "lightgray",
  },
  helper: {
    color: "red",
  },
}));

const ProviderContactForm = ({
  activityId,
  onSuccess = () => {},
  onFailure = () => {},
  onClose = () => {},
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = t("ProviderContact.Dialog.Validation.MissingEmail");
    } else if (!validator.isEmail(values.email)) {
      errors.email = t("ProviderContact.Dialog.Validation.InvalidEmail");
    }

    if (values.callback) {
      if (!values.phone) {
        errors.phone = t("ProviderContact.Dialog.Validation.MissingPhone");
      }
    }
    if (values.phone && !phoneNumberRegex.test(values.phone)) {
      errors.phone = t("ProviderContact.Dialog.Validation.InvalidPhone");
    }

    if (!values.message) {
      errors.message = t("ProviderContact.Dialog.Validation.MissingMessage");
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      await sendProviderRequest(activityId, {
        message: values.message,
        callbackRequested: values.callback,
        customer: {
          email: values.email,
          name: values.name,
          phone: values.phone,
        },
      });
      onSuccess();
    } catch (err) {
      console.log(err);
      onFailure(err);
    } finally {
      setIsSubmitting(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
      callback: false,
    },
    validate,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (values) => {
      handleSubmit(values);
      // onSuccess();
      // onFailure();
    },
  });

  const classes = useStyles();
  return (
    // <div style={{ width: "100%", maxWidth: "40ch" }}>
    <form
      onSubmit={formik.handleSubmit}
      className={classes.root}
      noValidate
      autoComplete="off"
    >
      <FormControl style={{ padding: 0, width: "100%", margin: 0 }}>
        <TextField
          InputLabelProps={{ className: classes.label }}
          FormHelperTextProps={{ className: classes.helper }}
          id="name"
          variant="outlined"
          size="small"
          name="name"
          label={t("ProviderContact.Dialog.Name")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          helperText={formik.touched.name && formik.errors.name}

          // placeholder="Name"
        />
      </FormControl>
      <FormControl style={{ padding: 0, width: "100%" }}>
        <TextField
          InputLabelProps={{ className: classes.label }}
          FormHelperTextProps={{ className: classes.helper }}
          id="email"
          type="email"
          variant="outlined"
          size="small"
          name="email"
          label={t("ProviderContact.Dialog.Email")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          helperText={formik.touched.email && formik.errors.email}
        />
      </FormControl>
      <FormControl style={{ padding: 0, width: "100%" }}>
        <TextField
          InputLabelProps={{ className: classes.label }}
          FormHelperTextProps={{ className: classes.helper }}
          inputProps={{ pattern: "tel" }}
          type="tel"
          id="phone"
          variant="outlined"
          size="small"
          name="phone"
          label={t("ProviderContact.Dialog.Phone")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phone}
          helperText={formik.touched.phone && formik.errors.phone}
        />
      </FormControl>
      <FormControl style={{ padding: 0, width: "100%" }}>
        <FormControlLabel
          style={{ marginLeft: 0 }}
          control={
            <Switch
              name="callback"
              onChange={formik.handleChange}
              value={formik.values.callback}
            />
          }
          label="Ich bitte um Rückruf"
        />
      </FormControl>
      <FormControl style={{ padding: 0, width: "100%" }}>
        <TextField
          InputLabelProps={{ className: classes.label }}
          FormHelperTextProps={{ className: classes.helper }}
          multiline
          minRows={4}
          id="message"
          variant="outlined"
          size="small"
          name="message"
          label={t("ProviderContact.Dialog.Message")}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
          helperText={formik.touched.message && formik.errors.message}
        />
      </FormControl>
      <FormControl style={{ padding: 0, width: "100%" }}>
        <Button
          style={{ marginTop: 8, marginBottom: 8 }}
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting
            ? t("ProviderContact.Dialog.Submitting")
            : t("ProviderContact.Dialog.Submit")}
        </Button>
      </FormControl>
      {/* {JSON.stringify(formik.errors)} */}
    </form>
    // </div>
  );
};

export default ProviderContactForm;
