import constants from "../constants";
import config from "../config";
const { destinations } = constants;
let whitelabel = false;
const getDestinationAndWLState = (host) => {
  const hostParts = host.split(".");
  whitelabel = hostParts[0] === "wl" ? true : config.isWhiteLabel;

  const dPart = whitelabel ? hostParts[1] : hostParts[0];
  if (dPart) {
    return {
      destination:
        Object.keys(destinations).find((item) => item === dPart) ||
        config.defaultDestination,
      isWhiteLabel: whitelabel,
    };
  } else {
    return {
      destination: config.defaultDestination,
      isWhiteLabel: whitelabel,
    };
  }
};
export const getProviderId = (wurl) => {
  const curl = new URL(wurl);
  return curl.searchParams.get("pid") || "";
};

export const { destination, isWhiteLabel } = getDestinationAndWLState(
  window.location.hostname
);

// export const destination = "ruegen";
// export const isWhiteLabel = true;
