import React, { useState, createRef } from "react";
import {
  Grid,
  Slide,
  Card,
  makeStyles,
  Typography,
  IconButton,
  CardHeader
} from "@material-ui/core";
import { colors } from "../../theme";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment/min/moment-with-locales";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useTranslation } from "react-i18next";
import { getTotalPriceBreakDown } from "../../helpers";
import { Constants } from "@actyvyst/common";
const { BookingStates } = Constants;
const useStyles = makeStyles((theme) => ({
  ticketBackground: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.actyLightBlue,
    marginTop: 15,
    [theme.breakpoints.down("xs")]: {
      width: "90%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%"
    },

    height: "auto"
  },
  ticketTitlebar: {
    backgroundColor: colors.actyLightBlue,
    height: 60,
    padding: 10,
    color: colors.actyDarkBlue
  },
  ticketBody: {
    backgroundColor: "white",

    padding: 10,
    color: colors.actyDarkBlue
  },
  ticketTitle: {
    color: "white",
    marginTop: 0
  },
  iconHolder: {
    position: "relative",
    top: 20,
    right: 20
  },
  icons: {
    color: "white"
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  unconfirmedTicket: {
    opacity: 0.1
  },
  ticketStatusStamp: {
    position: "relative",
    top: 90,
    left: 10,
    transform: "rotate(-45deg)",
    color: colors.actyGreen,
    zIndex: 10
  }
}));
const TicketCard = ({
  booking,
  showTicket = false,
  closeHandler,
  downloadHandler
}) => {
  const styles = useStyles();
  const { t, i18n } = useTranslation();
  moment.locale(i18n.language);
  return (
    <Slide direction="up" in={showTicket} mountOnEnter unmountOnExit>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        item
        xs={12}
      >
        <Card className={styles.ticketBackground}>
          <CardHeader
            className={styles.ticketTitlebar}
            title="actyvyst Ticket"
            titleTypographyProps={{ variant: "h2", color: "primary" }}
            subheader={`${booking.activity.name} - ${booking.variant.name}`}
            subheaderTypographyProps={{
              variant: "body2",
              component: "span",
              className: styles.ticketTitle
            }}
            action={
              <div className={styles.iconHolder}>
                {booking.status === BookingStates.CONFIRMED ? (
                  <IconButton
                    onClick={downloadHandler}
                    disableTouchRipple={true}
                    disableRipple={true}
                    disableFocusRipple={true}
                    className={styles.iconButton}
                  >
                    <GetAppIcon className={styles.icons} />
                  </IconButton>
                ) : undefined}

                <IconButton
                  onClick={closeHandler}
                  disableTouchRipple={true}
                  disableRipple={true}
                  disableFocusRipple={true}
                  className={styles.iconButton}
                >
                  {" "}
                  <CloseIcon className={styles.icons} />
                </IconButton>
              </div>
            }
          />

          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              className={styles.ticketBody}
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography variant="body2" align="center">
                {t("Booking.SelectedDate")}
              </Typography>
              <Typography variant="body2" component="span" align="center">
                {`\n${
                  moment(booking.slot.startDate).isSame(
                    moment(booking.slot.endDate),
                    "day"
                  )
                    ? moment(booking.slot.startDate).format(
                        t("DateFormatString")
                      )
                    : moment(booking.slot.startDate).format(
                        t("DateFormatString")
                      ) +
                      " - " +
                      moment(booking.slot.endDate).format(t("DateFormatString"))
                }\n${moment(booking.slot.startDate).format(
                  t("TimeFormatString")
                )} - ${moment(booking.slot.endDate).format(
                  t("TimeFormatString")
                )} ${t("General.clock")}`}
              </Typography>
              <Typography variant="body2" align="center">
                {`${getTotalPriceBreakDown(booking.priceBreakdown.categories)}`}
              </Typography>
              <Typography variant="body2" align="center">
                {t("Tickets.BookingId", { bookingId: booking.id })}
              </Typography>
              <br />
              {/* {booking.status === BookingStates.WAITING_FOR_CONFIRMATION ? (
                <Typography
                  variant="h3"
                  className={styles.ticketStatusStamp}
                  component="div"
                >
                  Wartet auf Bestätigung
                </Typography>
              ) : undefined} */}
              <img
                src={`${booking.ticketUri}`}
                border="0"
                alt="QRCode-Ticket"
                width="80%"
                className={
                  booking.status === BookingStates.WAITING_FOR_CONFIRMATION
                    ? styles.unconfirmedTicket
                    : undefined
                }
              />

              <Typography variant="caption" align="center">
                {t("Tickets.QRCodeHint")}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Slide>
  );
};

export default TicketCard;
