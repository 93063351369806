import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import theme, { colors } from "../../theme";
import constants from '../../constants';
import { Typography, Grid, makeStyles } from "@material-ui/core";
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
const { destinations } = constants;

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    color: colors.actyLightBlue,
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
    fontSize: "1rem"
  },
  dismissBtn: {
    boxShadow: "none",
    borderColor: colors.actyGray,
    borderWidth: 1,
    backgroundColor: "transparent",
    color: colors.actyGray,
    textTransform: "none",
    "&:active": {
      borderWidth: 1,
      borderColor: colors.actyGray,
      boxShadow: "none",
      backgroundColor: "transparent",
      color: "#ffffff"
    },
    "&:hover": {
      borderWidth: 1,
      borderColor: colors.actyGray,
      boxShadow: "none",
      backgroundColor: "transparent",
      color: colors.actyGray
    },
    "&:focus": {
      borderWidth: 1,
      borderColor: colors.actyGray,
      boxShadow: "none",

      backgroundColor: "transparent",
      color: colors.actyGray
    }
  },
  downloadBtn: {
    backgroundColor: colors.actyRed,
    "&:hover": {
      backgroundColor: colors.actyRed
    }
  }
}));

export default function DownloadDialog({
  open,
  handleClose,
  destination,
  isMobile,
  isMedium
}) {
  //   const [open, setOpen] = React.useState(toggleOpen);

  const classes = useStyles();
  const openLink = (link) => {
    window.location.href = link;
  };

  const isAndroid = /Android/i.test(navigator.userAgent);
  const isiOS = /iPad|iPhone|iPod/i.test(navigator.userAgent);

  const makeLink = (os, appId) => {
    switch (os) {
      case 'ios':
        return `https://itunes.apple.com/de/app/actyvyst/id${appId}`;
      case 'android':
        return `https://play.google.com/store/apps/details?id=${appId}`
      default:
        return '/'
    }
  }
  const destinationData = destinations[destination];

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="download-dialog-title"
        aria-describedby="download-dialog-description"
        onClose={handleClose}
      >
        <DialogTitle
          id="download-dialog-title"
          className={classes.dialogTitle}
        >
          App-Download
          <IconButton size="small" style={{ position: 'absolute', top: 12, right: 12 }}
            onClick={handleClose}
          ><CloseIcon /></IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="download-dialog-description">
            <Typography variant="body1" color="textPrimary">
              Lade Dir die <span style={{ fontFamily: 'Days One' }}>actyvyst <span style={{ color: colors.actyLightBlue }}>{`${destinationData.name}`}</span></span> App herunter und buche ein
              Angebot direkt in der App!
            </Typography>
          </DialogContentText>
        </DialogContent>
        {isAndroid === false &&
          isiOS === false
          ? (
            <DialogActions>
              <Grid container>
                <Grid item container xs={6} justifyContent="center">
                  <a
                    href={makeLink('ios', destinationData.appstoreId)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={"/img/apple-badge.png"}
                      alt="Jetzt im App Store laden"
                      style={{
                        height: 50
                      }}
                    />
                  </a>
                </Grid>
                <Grid item container xs={6} justifyContent="center">

                  <a
                    href={makeLink('android', destinationData.playstoreId)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={"/img/google-play-badge_s.png"}
                      alt="Jetzt bei Google Play laden"
                      style={{
                        height: 50
                      }}
                    />
                  </a>
                </Grid>
              </Grid>
            </DialogActions>

          ) : (
            <DialogActions>
              <Button
                onClick={handleClose}
                className={classes.dismissBtn}
                secondary
              >
                Abbrechen
              </Button>
              <Button
                onClick={() => openLink(makeLink(isiOS ? 'ios' : 'android', isiOS ? destinationData.appstoreId : destinationData.playstoreId))}
                secondary
                className={classes.downloadBtn}
              >
                zum Download
              </Button>
            </DialogActions>
          )}
      </Dialog>
    </div>
  );
}
