import React, { useState } from "react";
import {
  Menu,
  Button,
  IconButton,
  Box,
  makeStyles,
  Typography,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import OfferList from "./index";
import ContactInfo from "../ContactInfo";

import { colors } from "../../theme";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  offerBtn: {
    backgroundColor: colors.actyRed,
    "&:hover": {
      backgroundColor: colors.actyRed,
    },
    marginTop: 8,
    marginLeft: 10,
    minHeight: 40,
  },
  priceHeadLine: {
    color: colors.actyRed,
    paddingLeft: 15,
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
    fontSize: "1rem",
  },
  contactHeadLine: {
    color: colors.actyLightBlue,
    paddingLeft: 15,
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
    fontSize: "1rem",
  },
  dividerLine: {
    backgroundColor: colors.actyDarkBlue,
  },
}));

const HeaderOfferBox = React.forwardRef(
  ({ content, onOpenContactInfo = () => {}, onSelectItem }, ref) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();

    const toggleOpen = (event) => {
      if (content.length > 0) {
        if (anchorEl !== null) {
          setAnchorEl(null);
        } else {
          setAnchorEl(event.currentTarget);
        }
      } else {
        setOpen(!open);
      }
    };

    return content.length > 0 ? (
      <Box display="flex" flexDirection="column">
        <Button
          onClick={toggleOpen}
          className={classes.offerBtn}
          size="small"
          secondary
        >
          {t("ButtonActions.PricesShort")}
        </Button>
        <Menu
          ref={ref}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => toggleOpen()}
        >
          <div style={{ marginTop: 20, minWidth: 400 }}></div>
          <Typography variant="h5" className={classes.priceHeadLine}>
            {t("ButtonActions.Prices")}
          </Typography>
          <Divider variant="middle" className={classes.dividerLine} />
          <OfferList content={content} onSelectItem={onSelectItem} />
          <Typography variant="h5" className={classes.contactHeadLine}>
            {t("ProviderContact.Headline")}
          </Typography>
          <Divider variant="middle" className={classes.dividerLine} />
          <ContactInfo
            onOpenContactInfo={() => {
              toggleOpen();
              onOpenContactInfo();
            }}
          />

          <div
            style={{
              position: "absolute",
              right: 0,
              top: 2,
              marginRight: 2,
              marginTop: 2,
            }}
          >
            <IconButton onClick={toggleOpen} size="small">
              <CloseIcon />
            </IconButton>
          </div>
        </Menu>
      </Box>
    ) : (
      <Box display="flex" flexDirection="column">
        <Button
          onClick={onOpenContactInfo}
          className={classes.offerBtn}
          style={{ backgroundColor: colors.actyLightBlue }}
          secondary
        >
          {t("ProviderContact.Button")}
        </Button>
      </Box>
    );
  }
);

export default HeaderOfferBox;
