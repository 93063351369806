import { useContext, useMemo } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { AdCardMobile } from "./AdCardMobile";
import { AdCardNormal } from "./AdCardNormal";
import { useAppConfig } from "../../api/config";
import { ActivityContext } from "../../context/ActivitiesContext";

import config from "../../config";
import { isWhiteLabel } from "../../util/get-destination";

export const AdCard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { filters } = useContext(ActivityContext);
  const { appConfigData, appConfigLoading, appConfigError } = useAppConfig();

  const adPanel = appConfigData?.adpanel;
  const adCategories = adPanel?.type?.categoryfilter || [];

  const isActive = filters.categories.findIndex((category) => adCategories.includes(category)) > -1;

  const adData = adPanel?.active
    ? {
        isActive,
        image: {
          url: `${config.adMediaPath}${adPanel.image}`,
          altText: adPanel.title,
        },

        title: isActive ? adPanel.toggle?.title || "" : adPanel.title,
        teaser: isActive ? adPanel.toggle?.text || "" : adPanel.text,
        buttonTitle: isActive ? adPanel.toggle?.linkText || "" : adPanel.linkText,

        link: isActive ? "/" : `/?categories=${adPanel.type.categoryfilter.join(",")}`,
      }
    : null;

  if (appConfigLoading) return <></>;
  if (appConfigError) return <></>;
  if (!adData) return <></>;
  if (isWhiteLabel) return <></>;

  return isMobile ? <AdCardMobile {...adData} /> : <AdCardNormal {...adData} />;
};
