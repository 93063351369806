import { useData } from "./useData";

function useSlots(variant) {
  const { data, error, loading, retry } = useData(
    `/activities/variants/${variant}/slots`
  );

  return {
    data: data,
    loading: loading,
    error: error,
    retry
  };
}

export { useSlots };
